import { Component, OnDestroy, OnInit, SecurityContext } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { map, takeUntil } from "rxjs/operators";
import { filter, Subject, take } from "rxjs";
import { MdbModalService } from "mdb-angular-ui-kit/modal";
import { Actions, ofType } from "@ngrx/effects";
import { CompanyDocument, LsHttpErrorResponse } from "../Models";
import { CompanyDocumentType } from "../Models/Enums";
import { FileUploadError, SortEvent } from "../Models/Interfaces";
import { IColumnHeader } from "../Models/Interfaces/IColumnHeader";
import { DeleteDocumentComponent } from "../Modules/COT-Module/Modals";
import { UploadStatus } from "../Models/Enums/UploadStatus";
import { FileHandle } from "../../Elements/upload-widget/FileHandle";
import { CompanyDocumentActions } from "../Modules/COT-Module/OnboardingStateManagement/CompanyDocument/company-document-actions";
import { ModalActions } from "./ModalActions";
import { DomSanitizer } from "@angular/platform-browser";
import { RouteStepDataBaseComponent } from "./route-step-data-base.component";
import { RouteStepDataService } from "../Modules/COT-Module/Services";
import { SortDirection } from "@limestone/ls-shared-modules";
import { FileUtilityService } from "../../services/file-utility.service";

@Component({ selector: "ls-rmh", template: "" })
export abstract class FileUploadBaseComponent extends RouteStepDataBaseComponent implements OnInit, OnDestroy {
	public componentTeardown$ = new Subject();
	public files?: CompanyDocument[];
	public validFiles?: CompanyDocument[] = [];
	public erroredFiles?: CompanyDocument[] = [];
	public allowedFileExtensions: string[];
	public errors: string[] = [];
	public maxFileSize = 50; // In MB
	public maxFileCount = 10;
	public disabled = false;
	public submitted = false;
	private companyId?: number;
	private docType?: CompanyDocumentType;

	constructor(
		public router: Router,
		public store: Store,
		public dialog: MdbModalService,
		public actions$: Actions,
		public sanitizer: DomSanitizer,
		public routeStepDataService: RouteStepDataService
	) {
		super(routeStepDataService);
		this.docType = this.activeRouteData!.docType!;
		this.allowedFileExtensions = ["PDF", "xls", "xlsx", "CSV", "unencrypted ZIP"];
	}

	ngOnInit() {
		this.actions$
			.pipe(
				takeUntil(this.componentTeardown$),
				ofType(CompanyDocumentActions.saveUnsuccessful),
				map((act) => {
					const errMap = act.result.errors as Map<string, string[]>;

					errMap.forEach((val, key) => {
						switch (key) {
							case "DuplicateFileExists": {
								this.errors.push(
									this.sanitizer.sanitize(
										SecurityContext.HTML,
										`You’ve already uploaded <strong>${val}</strong>. Please try again by uploading a different file.`
									) ?? ""
								);
								break;
							}
							case "FileSizeExceeded": {
								this.errors.push(
									this.sanitizer.sanitize(
										SecurityContext.HTML,
										`<strong>${val}</strong> exceeds the maximum file size. Please upload documents smaller than 50 MB.`
									) ?? ""
								);
								break;
							}
							case "UnsupportedFileType": {
								this.errors.push(
									this.sanitizer.sanitize(
										SecurityContext.HTML,
										`The file type for <strong>${val}</strong> isn’t permitted. Try again with any of the allowed file types: ${this.allowedFileExtensions.join(", ")}.`
									) ?? ""
								);
								break;
							}
						}
						const currentFileName = val[0];

						this.files?.forEach((f) => {
							if (f.status === UploadStatus.IN_PROGRESS && currentFileName === f.fileName) {
								f.status = UploadStatus.FAILED;
							}
						});
					});

					this.checkIfShouldDisable();
				})
			)
			.subscribe();
	}

	public getDocType(): CompanyDocumentType {
		return this.docType!;
	}

	public getComponentTearDown(): Subject<any> {
		return this.componentTeardown$;
	}

	public setCompanyId(id: number): void {
		this.companyId = id;
	}

	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	public navTo() {}

	public handleSortChange(sort: SortEvent) {
		const sortOrder = sort.sort.direction;
		const sortColumn = sort.sort.active === undefined ? "fileName" : sort.sort.active;
		if (!CompanyDocument.ColumnNames().some((c: IColumnHeader) => c.relatedField === sortColumn)) {
			throw new Error(`Column ${sortColumn} does not exist on CompanyDocument.`);
		}
		if (this.files === undefined || this.files?.length === 0) {
			return;
		}

		let filesSort;
		if (sortOrder === SortDirection.DESC) {
			filesSort = this.files.sort((a: any, b: any) =>
				a![sortColumn].toLowerCase() < b![sortColumn].toLowerCase() ? 1 : -1
			);
		} else {
			filesSort = this.files.sort((a: any, b: any) =>
				a![sortColumn].toLowerCase() > b![sortColumn].toLowerCase() ? 1 : -1
			);
		}
		this.files = filesSort;
	}

	public handleError(error: FileUploadError) {
		this.errors.push(error.errorMessage);
		this.files?.push(
			new CompanyDocument(
				this.companyId,
				error.file.name,
				FileUtilityService.convertFileSize(error.file.size),
				new Date(),
				UploadStatus.FAILED,
				this.docType
			)
		);
	}

	public openFile(file: CompanyDocument) {
		this.store.dispatch(
			CompanyDocumentActions.downloadFile({
				companyId: file.companyId!,
				documentType: this.docType!,
				fileName: file.fileName!
			})
		);
	}

	public deleteDocument(document: CompanyDocument) {
		this.dialog
			.open(DeleteDocumentComponent, {
				modalClass: "modal-dialog-centered modal-fullscreen-sm-down modal-lg",
				ignoreBackdropClick: true,
				data: { fileName: document.fileName }
			})
			.onClose.pipe(
				filter((result: ModalActions) => result === ModalActions.PRIMARY),
				take(1),
				map((result) => {
					this.store.dispatch(
						CompanyDocumentActions.deleteCompanyDocument({
							id: document.companyId!,
							documentType: document.documentType!,
							document
						})
					);
				})
			)
			.subscribe();
		this.checkIfShouldDisable();
	}

	public uploadFiles(files: FileHandle[]) {
		const uploadErrors: Map<string, string[]> = new Map<string, string[]>();
		const duplicateFiles: string[] = [];
		const unsupportedFileTypeFiles: string[] = [];
		const oversizedFiles: string[] = [];

		// Check if file count is over limit.
		if (this.fileCountOverLimit(files) || this.submitted) {
			return;
		}

		files.forEach((f) => {
			let documentInErrorState = false;

			const companyDocument: CompanyDocument = new CompanyDocument(
				this.companyId,
				f.file.name,
				FileUtilityService.convertFileSize(f.file.size),
				new Date(),
				UploadStatus.IN_PROGRESS,
				this.docType
			);

			// Check if duplicate files exist.
			const duplicateFile = this.getDuplicateFilesIfExists();
			if (duplicateFile != undefined) {
				documentInErrorState = true;
				duplicateFiles.push(duplicateFile);
				uploadErrors.set("DuplicateFileExists", duplicateFiles);
			}

			// Check if the file is supported.
			const fileTypeSupported = FileUtilityService.fileTypeSupported(f, this.allowedFileExtensions);
			if (!fileTypeSupported) {
				documentInErrorState = true;
				unsupportedFileTypeFiles.push(f.file.name);
				uploadErrors.set("UnsupportedFileType", unsupportedFileTypeFiles);
			}

			// Check if file size is over limit.
			if (FileUtilityService.isFileSizeOverLimit(f, this.maxFileSize)) {
				documentInErrorState = true;
				oversizedFiles.push(f.file.name);
				uploadErrors.set("FileSizeExceeded", oversizedFiles);
			}

			if (documentInErrorState) {
				this.erroredFiles!.push(companyDocument);
			} else {
				this.validFiles!.push(companyDocument);
			}

			this.files?.push(companyDocument);
		});

		const filesToUpload = files.filter((f1) => this.validFiles?.find((f2) => f1.file.name == f2.fileName));
		const failedFiles = files.filter((f1) => this.erroredFiles?.find((f2) => f1.file.name == f2.fileName));

		if (filesToUpload.length > 0) {
			this.store.dispatch(
				CompanyDocumentActions.uploadFiles({
					companyId: this.companyId!,
					documentType: this.docType!,
					files: filesToUpload
				})
			);
		}

		if (this.erroredFiles!.length > 0) {
			const errorResponses = this.createErrorResponses(uploadErrors);
			errorResponses.forEach((e) => {
				this.dispatchSaveUnsuccessful(e, failedFiles);
			});
		}

		this.checkIfShouldDisable();
	}

	private createErrorResponses(uploadErrors: Map<string, string[]>): LsHttpErrorResponse[] {
		const errorResponses: LsHttpErrorResponse[] = [];
		for (const [key, value] of uploadErrors) {
			value.forEach((e) => {
				const errResp = new LsHttpErrorResponse(undefined, undefined, undefined, undefined, undefined);
				errResp.errors = new Map<string, string[]>([[key, [e]]]);
				errorResponses.push(errResp);
			});
		}
		return errorResponses;
	}

	public isDisabled(): boolean {
		return !(this.files && this.files.some((f) => f.status === UploadStatus.COMPLETE));
	}

	public rebuildFilesListAfterUpload(uploadedFiles: CompanyDocument[]): any[] {
		const failedFiles = this.files?.filter((f) => f.status == UploadStatus.FAILED);
		return [...(failedFiles ?? []), ...(uploadedFiles ?? [])];
	}

	private dispatchSaveUnsuccessful(errResp: LsHttpErrorResponse, files: FileHandle[]) {
		this.store.dispatch(CompanyDocumentActions.saveUnsuccessful({ files, result: errResp }));
	}

	private getDuplicateFilesIfExists(): any {
		const fileNames = this.files?.map((f) => f.fileName);
		const duplicate = fileNames?.find((d, index) => {
			return fileNames.find((x, ind) => x === d && index !== ind);
		});
		return duplicate;
	}

	private fileCountOverLimit(files: FileHandle[]): boolean {
		const completeOrInprogressFiles = this.getCompletedOrInProgressFiles();
		if (completeOrInprogressFiles) {
			return completeOrInprogressFiles.length + files.length > this.maxFileCount;
		} else {
			return files.length > this.maxFileCount;
		}
	}

	private getCompletedOrInProgressFiles(): CompanyDocument[] {
		return this.files!.filter((file) => [UploadStatus.IN_PROGRESS, UploadStatus.COMPLETE].includes(file.status!));
	}

	private checkIfShouldDisable(): void {
		this.disabled = this.getCompletedOrInProgressFiles().length === this.maxFileCount;
	}

	public abstract initData(): void;
}
