import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject, filter, map, takeUntil, combineLatest, take } from "rxjs";
import { Store } from "@ngrx/store";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "../../../../AppStateManagement";
import { ActivatedRoute, Router } from "@angular/router";
import { CompanyProfile } from "../../../../Models";
import { ContinueApplicationService } from "../../../../../services";
import { IColumnHeader } from "src/app/Models/Interfaces/IColumnHeader";
import { Application } from "src/app/Models/Application";
import { GridService } from "src/services";
import { DatePipe } from "@angular/common";
import {
	GridSort,
	SortDirection,
	OnboardApplicationStatus,
	PageRequest,
	FeatureFlagSelectors,
	FeatureFlags
} from "@limestone/ls-shared-modules";
import { CompanyProgressService } from "../../../COT-Module/Services";
@Component({
	selector: "ls-applications",
	templateUrl: "./applications.component.html",
	styleUrls: ["./applications.component.scss"]
})
export class ApplicationsComponent implements OnInit, OnDestroy {
	public static INITIAL_SORT: GridSort = { active: "lastActivity", direction: SortDirection.DESC };
	protected componentTeardown$ = new Subject();
	public isSCF_Onboarding_V1_0_0_FlagEnabled?: boolean;
	public hasArfAppWithNoCustomers?: boolean;

	constructor(
		public store: Store,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		private continueApplicationService: ContinueApplicationService,
		public gridService: GridService<Application>,
		private companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		private datePipe: DatePipe,
		private companyProfileSelectors: CompanyProfileSelectors,
		public featureFlagSelectors: FeatureFlagSelectors,
		private companyProgressService: CompanyProgressService
	) {
		gridService.activeSort = { active: Application.DefaultSort, direction: SortDirection.DESC };
	}

	public companyProfile?: CompanyProfile;
	public applications: Application[] = [];
	public columns: IColumnHeader[] = Application.ColumnNames;

	public get OnboardApplicationStatus(): typeof OnboardApplicationStatus {
		return OnboardApplicationStatus;
	}

	ngOnInit() {
		combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.companyProfileRelationshipSelectors.selectApplications),
			this.store.select(this.featureFlagSelectors.selectFeatureFlags)
		])
			.pipe(
				takeUntil(this.componentTeardown$),
				filter(([cp, apps, ff]) => !!cp && !!apps && !!ff),
				map(([cp, apps, ff]) => {
					this.companyProfile = cp;
					this.gridService.activePage = apps;
					this.applications = apps!.content!;
					this.isSCF_Onboarding_V1_0_0_FlagEnabled = ff!.find(
						(f) => f.id === FeatureFlags.SCF_Onboarding_V1_0_0
					)!.enabled;
					this.hasArfAppWithNoCustomers = cp!.hasArfAppWithNoCustomers;
				})
			)
			.subscribe();

		this.gridService.sortFilterChange$
			.pipe(
				takeUntil(this.componentTeardown$),
				map((pr: PageRequest) => {
					this.store.dispatch(
						CompanyProfileRelationshipActions.getApplications({
							companyProfileId: this.companyProfile!.companyId!,
							pr
						})
					);
				})
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	moreFinancing() {
		if (this.isSCF_Onboarding_V1_0_0_FlagEnabled) {
			this.router.navigate(["../marketplace"], { relativeTo: this.activatedRoute });
		} else {
			if (!this.activatedRoute.snapshot.paramMap.has("flowId")) throw new Error("FlowId is missing");
			this.router.navigate(
				[`../../onboarding/${this.activatedRoute.snapshot.paramMap.get("flowId")}/customer-setup/manual`],
				{ relativeTo: this.activatedRoute }
			);
		}
	}

	onRowClicked(app: Application) {
		if (app.applicationStatusId === OnboardApplicationStatus.INPROGRESS) {
			this.companyProgressService
				.getOnboardCompanyProgress(this.companyProfile!.companyId!, app.productId)
				.pipe(
					take(1),
					map(() => {
						if (app.productId === "ARF") {
							this.continueApplicationService.continueCustomerSetup(app.companyId!, app.currentSubStep!);
						} else {
							this.continueApplicationService.continueApplication();
						}
					})
				)
				.subscribe();
		} else {
			this.router.navigate([`../applications/${app.companyId}`], { relativeTo: this.activatedRoute });
		}
	}

	continueApplication() {
		this.companyProgressService
			.getOnboardCompanyProgress(this.companyProfile!.companyId!, "ARF")
			.pipe(
				take(1),
				map(() => this.continueApplicationService.continueApplication())
			)
			.subscribe();
	}

	getLastActivity(date?: Date): string {
		if (date) {
			return this.calculateDiff(date);
		} else {
			return "";
		}
	}

	calculateDiff(date: Date, date2: Date = new Date()): string {
		let dateStr = this.datePipe.transform(date, "MMM dd, YYYY")!;
		if (
			date2.getUTCFullYear() === date.getUTCFullYear() &&
			(date2.getUTCMonth() === date.getUTCMonth() || date2.getUTCMonth() - date.getUTCMonth() === 1)
		) {
			const diff = date2.getUTCDate() - date.getUTCDate();
			if (date2.getUTCMonth() - date.getUTCMonth() === 1) {
				date2.setUTCDate(date2.getUTCDate() - 1);

				if (date2.getUTCDate() === date.getUTCDate()) {
					dateStr = "Yesterday";
				}
			} else if (diff < 1) {
				dateStr = "Today";
			} else if (diff >= 1 && diff < 2) {
				dateStr = "Yesterday";
			}
		}
		return dateStr;
	}
}
