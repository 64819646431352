<div class="d-flex flex-column">
  <ls-upload
    [files]="files"
    [nextButtonDisabled]="isNextButtonDisabled()"
    [allowedFileExtensions]="allowedFileExtensions"
    [maxFileCount]="maxFileCount"
    [maxFileSize]="maxFileSize"
    [disabled]="disabled"
    [submitted]="submitted"
    (nextClicked)="continue()"
    (sortChanged)="handleSortChange($event)"
    (openFileClicked)="openFile($event)"
    (deleteFileClicked)="deleteDocument($event)"
    (uploadedFiles)="uploadFiles($event)"
    (fileUploadError)="handleError($event)">
    <h1 header class="ls-mb-5 l">{{ headerText }}</h1>
    <div description class="d-flex flex-column ls-mb-11">
      <ng-container *ngIf="isUSBank">
        <p class="l ls-mb-3">Please upload a photo of a voided check with the printed company name for the bank account where you receive customer payments.</p>
        <p class="l ls-mb-3">A voided check will provide us with the bank routing and account information where you receive your customer payments. For any wire or ACH payments, we will pay to the same bank account.</p>
        <p class="l ls-mb-0">Alternatively, you can upload a signed letter from your bank, on bank letterhead, verifying the following:</p>
      </ng-container>
      <p *ngIf="!isUSBank" class="l ls-mb-0">Please upload a signed letter from your bank, on bank letterhead, verifying the following:</p>
      <p class="l ls-mb-0">
        <li class="ls-pl-3">Account name</li>
        <li class="ls-pl-3">Account number</li>
        <li class="ls-pl-3">Routing number</li>
        The letter must be less than 3 months old.
      </p>
    </div>
    <div *ngIf="errors && errors.length > 0" class="d-flex flex-column ls-mt-4" errors>
      <div *ngFor="let error of errors">
        <ls-alert alertType="danger" [small]="true"><p [innerHTML]="error" class="s ls-mb-0"></p></ls-alert>
      </div>
    </div>
  </ls-upload>
</div>
