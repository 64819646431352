import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { MdbModalService } from "mdb-angular-ui-kit/modal";
import { combineLatest, filter, map, Subject, take, takeUntil } from "rxjs";
import { CompanyProfile } from "src/app/Models";
import { BankAccountPersuasionComponent } from "../../../Modals";
import { CodatActions, CompanyProfileSelectors } from "../../../../../AppStateManagement";
import { ModalActions } from "../../../../../Core";
import { RouteStepDataBaseComponent } from "src/app/Core/route-step-data-base.component";
import { RouteStepDataService } from "../../../Services";
import { IHtmlContent } from "src/app/Models/Interfaces";
import { Flow } from "src/app/Models/Enums";

@Component({
	selector: "ls-link-bank-account",
	templateUrl: "./link-bank-account.component.html",
	styleUrls: ["./link-bank-account.component.scss"]
})
export class LinkBankAccountComponent extends RouteStepDataBaseComponent implements OnInit {
	protected componentTeardown$ = new Subject();
	public data: ConnectBankContent;
	public flow?: string;
	constructor(
		public router: Router,
		public store: Store,
		public companyProfileSelectors: CompanyProfileSelectors,
		private dialog: MdbModalService,
		public routeStepDataService: RouteStepDataService
	) {
		super(routeStepDataService);
		const htmlContent: any = this.activeRouteData!.htmlContent;
		this.data = new ConnectBankContent(
			htmlContent.paragraphs,
			htmlContent.firstButtonText,
			htmlContent.secondButtonText
		);
	}

	companyProfile?: CompanyProfile;
	path = "onboarding/connect-financials/banking-success";

	ngOnInit() {
		combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.companyProfileSelectors.selectOnboardCompanyProgress)
		])
			.pipe(
				filter(([cp, ocp]) => !!cp && !!ocp),
				takeUntil(this.componentTeardown$),
				map(([cp, ocp]) => {
					this.companyProfile = cp;
					this.flow = ocp?.onboardingConfiguration?.id;
				})
			)
			.subscribe();
	}

	connectAccounts() {
		this.store.dispatch(
			CodatActions.createConnection({ companyProfile: this.companyProfile!, redirectPath: this.path })
		);
	}

	openModal() {
		if (this.flow === Flow.SCF_V1) {
			this.nav(1);
			return;
		}
		this.dialog
			.open(BankAccountPersuasionComponent, {
				modalClass: "modal-dialog-centered modal-fullscreen-sm-down modal-lg",
				ignoreBackdropClick: true
			})
			.onClose.pipe(
				take(1),
				map((action: ModalActions) => {
					if (action === ModalActions.PRIMARY) {
						this.store.dispatch(
							CodatActions.createConnection({ companyProfile: this.companyProfile!, redirectPath: this.path })
						);
					} else {
						this.nav();
					}
				})
			)
			.subscribe();
	}
}

class ConnectBankContent implements IHtmlContent {
	public paragraphs?: string[];
	public firstButtonText?: string;
	public secondButtonText?: string;

	constructor(paragraphs?: string[], firstButtonText?: string, secondButtonText?: string) {
		this.paragraphs = paragraphs;
		this.firstButtonText = firstButtonText;
		this.secondButtonText = secondButtonText;
	}
}
