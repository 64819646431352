import { Address } from "./Address";
import { Contact } from "./Contact";
import { CurrencyCode, GenericLookup } from "@limestone/ls-shared-modules";

export class CompanyProfileRelationship {
	companyProfileId?: number;
	productId?: string;
	relationshipCompanyId?: number;
	relationshipCompanyName?: string;
	billingAddress?: Address;
	primaryContact?: Contact;
	accountsPayableContact?: Contact;
	instrumentPlatform?: GenericLookup<string>;
	instrumentApprovalPlatform?: GenericLookup<string>;
	remittancePlatform?: GenericLookup<string>;
	relationshipDurationId?: string;
	paymentTerms?: number;
	currencyCode?: CurrencyCode;
	onboardPaymentMethodId?: string;
	isOtherCurrency?: boolean;
	preferredPaymentMethodId?: string;
	onboardInstrumentRangeId?: string;
	onboardRevenueRangeId?: string;
	arBalanceRangeId?: string;
	isInstrumentApprovalConfirmed?: boolean;
	isAutoXcel?: boolean;
	financeDisclosureId?: string;
	isFinanceExpedited?: boolean;
	requestedCreditLimit?: number;
	isManualSetup?: boolean;
	isCustomerSetupComplete?: boolean;
	applicationStatusId?: string;
	applicationStatusName?: string;
	currentSubStep?: number;
	isCodatCalculationsComplete?: boolean;
	createDate?: Date;
	lienCollaborationYesNoId?: string;
	subsidiaryCompanies?: string[];

	constructor(
		companyProfileId?: number,
		productId?: string,
		relationshipCompanyId?: number,
		relationshipCompanyName?: string,
		billingAddress?: Address,
		primaryContact?: Contact,
		accountsPayableContact?: Contact,
		instrumentPlatform?: GenericLookup<string>,
		instrumentApprovalPlatform?: GenericLookup<string>,
		remittancePlatform?: GenericLookup<string>,
		relationshipDurationId?: string,
		paymentTerms?: number,
		currencyCode?: CurrencyCode,
		onboardPaymentMethodId?: string,
		isOtherCurrency?: boolean,
		preferredPaymentMethodId?: string,
		onboardInstrumentRangeId?: string,
		onboardRevenueRangeId?: string,
		arBalanceRangeId?: string,
		isInstrumentApprovalConfirmed?: boolean,
		isAutoXcel?: boolean,
		financeDisclosureId?: string,
		isFinanceExpedited?: boolean,
		requestedCreditLimit?: number,
		isManualSetup?: boolean,
		isCustomerSetupComplete?: boolean,
		applicationStatusId?: string,
		applicationStatusName?: string,
		currentSubStep?: number,
		isCodatCalculationsComplete?: boolean,
		createDate?: Date,
		lienCollaborationYesNoId?: string,
		subsidiaryCompanies?: string[]
	) {
		this.companyProfileId = companyProfileId;
		this.productId = productId;
		this.relationshipCompanyId = relationshipCompanyId;
		this.relationshipCompanyName = relationshipCompanyName;
		this.billingAddress = new Address(
			billingAddress?.id,
			billingAddress?.address1,
			billingAddress?.address2,
			billingAddress?.city,
			billingAddress?.state,
			billingAddress?.country,
			billingAddress?.postalCode
		);
		this.primaryContact = primaryContact
			? new Contact(
					primaryContact?.id,
					primaryContact?.firstName,
					primaryContact?.lastName,
					primaryContact?.phone?.countryCode,
					primaryContact?.phone?.phoneCode,
					primaryContact?.phone?.number,
					primaryContact?.phone?.extension,
					primaryContact?.email,
					primaryContact?.contactType
				)
			: undefined;
		this.accountsPayableContact = accountsPayableContact
			? new Contact(
					accountsPayableContact?.id,
					accountsPayableContact?.firstName,
					accountsPayableContact?.lastName,
					accountsPayableContact?.phone?.countryCode,
					accountsPayableContact?.phone?.phoneCode,
					accountsPayableContact?.phone?.number,
					accountsPayableContact?.phone?.extension,
					accountsPayableContact?.email,
					accountsPayableContact?.contactType
				)
			: undefined;
		this.instrumentPlatform = instrumentPlatform
			? new GenericLookup<string>(instrumentPlatform.id, instrumentPlatform.name, instrumentPlatform?.isActive)
			: undefined;
		this.instrumentApprovalPlatform = instrumentApprovalPlatform
			? new GenericLookup<string>(
					instrumentApprovalPlatform.id,
					instrumentApprovalPlatform.name,
					instrumentApprovalPlatform?.isActive
				)
			: undefined;
		this.remittancePlatform = remittancePlatform
			? new GenericLookup<string>(remittancePlatform.id, remittancePlatform.name, remittancePlatform?.isActive)
			: undefined;
		this.relationshipDurationId = relationshipDurationId;
		this.paymentTerms = paymentTerms;
		this.currencyCode = currencyCode;
		this.onboardPaymentMethodId = onboardPaymentMethodId;
		this.onboardInstrumentRangeId = onboardInstrumentRangeId;
		this.onboardRevenueRangeId = onboardRevenueRangeId;
		this.arBalanceRangeId = arBalanceRangeId;
		this.isOtherCurrency = isOtherCurrency;
		this.preferredPaymentMethodId = preferredPaymentMethodId;
		this.isInstrumentApprovalConfirmed =
			isInstrumentApprovalConfirmed === undefined || isInstrumentApprovalConfirmed === null
				? true
				: isInstrumentApprovalConfirmed;
		this.isAutoXcel = isAutoXcel === undefined || isAutoXcel === null ? true : isAutoXcel;
		this.financeDisclosureId = financeDisclosureId;
		this.isFinanceExpedited =
			isFinanceExpedited === undefined || isFinanceExpedited === null ? false : isFinanceExpedited;
		this.requestedCreditLimit = requestedCreditLimit;
		this.isManualSetup = isManualSetup;
		this.isCustomerSetupComplete = isCustomerSetupComplete;
		this.applicationStatusId = applicationStatusId;
		this.applicationStatusName = applicationStatusName;
		this.currentSubStep = currentSubStep ?? 0;
		this.isCodatCalculationsComplete = isCodatCalculationsComplete;
		this.createDate = createDate;
		this.lienCollaborationYesNoId = lienCollaborationYesNoId;
		this.subsidiaryCompanies = subsidiaryCompanies;
	}
}
