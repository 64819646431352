import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import {
	AbstractControl,
	ControlValueAccessor,
	FormControl,
	FormGroup,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
	Validator,
	Validators
} from "@angular/forms";
import { Store } from "@ngrx/store";
import copy from "fast-copy";
import { combineLatest, debounce, filter, interval, map, Subject, takeUntil } from "rxjs";
import { Address, GoogleAutocompletePrediction, GooglePlaceDetail, GooglePlaceDetails } from "src/app/Models";
import { GoogleActions } from "../../../AppStateManagement";
import { GoogleAddress } from "../../../Models/Enums";
import { Guid } from "src/Utils";
import { Actions, ofType } from "@ngrx/effects";
import {
	Country,
	CountryCode,
	GenericLookup,
	GenericSelectors,
	LookupTables,
	StateProvince
} from "@limestone/ls-shared-modules";

@Component({
	selector: "ls-address-form-control",
	templateUrl: "./address-form-control.component.html",
	styleUrls: ["./address-form-control.component.scss"],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: AddressFormControlComponent, multi: true },
		{ provide: NG_VALIDATORS, useExisting: AddressFormControlComponent, multi: true }
	]
})
export class AddressFormControlComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
	protected componentTeardown$ = new Subject();

	id: string;

	ADDRESS1 = "address1";
	ADDRESS2 = "address2";
	CITY = "city";
	COUNTRY = "country";
	STATE = "state";
	POSTAL_CODE = "postalCode";

	formGroup: FormGroup = new FormGroup({});
	countries: Country[] = [];
	stateProvinces: StateProvince[] = [];

	public predictions?: GoogleAutocompletePrediction[];
	public filteredStateProvinces: StateProvince[] = [];

	constructor(
		private store: Store<any>,
		private actions$: Actions,
		private genericSelectors: GenericSelectors,
		private guid: Guid
	) {
		this.id = this.guid.New();
		this.ADDRESS1 = "address1";
		this.ADDRESS2 = "address2";
		this.CITY = "city";
		this.COUNTRY = "country";
		this.STATE = "state";
		this.POSTAL_CODE = "postalCode";

		this.formGroup.addControl(this.ADDRESS1, new FormControl(null, Validators.required));
		this.formGroup.addControl(this.ADDRESS2, new FormControl(null));
		this.formGroup.addControl(this.CITY, new FormControl(null, Validators.required));
		this.formGroup.addControl(this.COUNTRY, new FormControl(null, Validators.required));
		this.formGroup.addControl(this.STATE, new FormControl(null));
		this.formGroup.addControl(this.POSTAL_CODE, new FormControl(null, Validators.required));
	}

	ngOnInit() {
		combineLatest([
			this.store.select(this.genericSelectors.selectCountries),
			this.store.select(this.genericSelectors.selectStateProvinces)
		])
			.pipe(
				filter(([c, sp]) => !!c && !!sp),
				takeUntil(this.componentTeardown$),
				map(([c, sp]) => {
					this.countries = c!;
					this.stateProvinces = sp!;
					const countryCode = this.formGroup?.get(this.COUNTRY)?.value?.code;
					if (countryCode) {
						this.filteredStateProvinces = copy(this.stateProvinces).filter((sp) => sp.country === countryCode);
						countryCode === CountryCode.US || countryCode === CountryCode.CA
							? this.formGroup.get(this.STATE)?.enable({ emitEvent: false })
							: this.formGroup.get(this.STATE)?.disable({ emitEvent: false });
					}
				})
			)
			.subscribe();

		this.actions$
			.pipe(
				ofType(GoogleActions.setAutocompleteAddresses),
				takeUntil(this.componentTeardown$),
				filter((action) => action.id === this.id),
				map((action) => {
					this.predictions = action.addresses.predictions;
				})
			)
			.subscribe();

		this.actions$
			.pipe(
				ofType(GoogleActions.setPlaceDetails),
				takeUntil(this.componentTeardown$),
				filter((action) => action.id === this.id),
				map((action) => {
					this.setAddressFields(action.details);
				})
			)
			.subscribe();

		this.formGroup
			?.get(this.ADDRESS1)
			?.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				filter((val) => typeof val === "string" && this.formGroup.get(this.ADDRESS1)!.dirty),
				debounce(() => interval(200)),
				map((val) => this.store.dispatch(GoogleActions.getAutocompleteAddresses({ id: this.id, name: val })))
			)
			.subscribe();

		this.formGroup
			?.get(this.COUNTRY)
			?.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				map((country: Country) => {
					this.filteredStateProvinces = copy(this.stateProvinces).filter((sp) => sp.country === country?.code);
					if (country?.code !== CountryCode.US && country?.code !== CountryCode.CA) {
						this.formGroup.get(this.STATE)?.clearValidators();
						this.formGroup.get(this.STATE)?.setValue("");
						this.formGroup.get(this.STATE)?.disable();
					} else {
						this.formGroup.get(this.STATE)?.addValidators(Validators.required);
						this.formGroup.get(this.STATE)?.updateValueAndValidity();
						if (this.formGroup.get(this.COUNTRY)!.dirty) {
							this.formGroup.get(this.STATE)?.setValue("");
						}
						this.formGroup.get(this.STATE)?.enable();
					}
				})
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	compareCountryOrStateProvince(a: Country | StateProvince, b: Country | StateProvince): boolean {
		return a !== null && b !== null && a.code === b.code;
	}

	writeValue(address?: Address): void {
		this.formGroup.get(this.ADDRESS1)?.setValue(address?.address1 ?? null, { emitEvent: false });
		this.formGroup.get(this.ADDRESS2)?.setValue(address?.address2, { emitEvent: false });
		this.formGroup.get(this.CITY)?.setValue(address?.city, { emitEvent: false });
		this.formGroup.get(this.COUNTRY)?.setValue(address?.country, { emitEvent: false });
		this.formGroup.get(this.STATE)?.setValue(address?.state, { emitEvent: false });
		this.formGroup.get(this.POSTAL_CODE)?.setValue(address?.postalCode, { emitEvent: false });
	}

	registerOnChange(onChange: (value: Address | null) => void): void {
		this.formGroup.valueChanges.pipe(takeUntil(this.componentTeardown$), map(onChange)).subscribe();
	}

	onTouched() {}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		isDisabled ? this.formGroup.disable() : this.formGroup.enable();
	}

	validate(control: AbstractControl<any, any>): ValidationErrors | null {
		if (this.formGroup.valid) {
			return null;
		}

		let errors: any = {};

		errors = this.addControlErrors(errors, this.ADDRESS1);
		errors = this.addControlErrors(errors, this.ADDRESS2);
		errors = this.addControlErrors(errors, this.CITY);
		errors = this.addControlErrors(errors, this.COUNTRY);
		errors = this.addControlErrors(errors, this.STATE);
		errors = this.addControlErrors(errors, this.POSTAL_CODE);

		return errors;
	}

	addControlErrors(allErrors: any, controlName: string) {
		const errors = { ...allErrors };

		const controlErrors = this.formGroup.controls[controlName].errors;

		if (controlErrors) {
			errors[controlName] = controlErrors;
		}

		return errors;
	}

	registerOnValidatorChange?(fn: () => void): void {
		this.validatorChange = fn;
	}

	validatorChange() {}

	getDisplayValue(option: GoogleAutocompletePrediction): string {
		return option.description ? option.description : "";
	}

	onSelect(option: any) {
		this.store.dispatch(GoogleActions.getPlaceDetails({ id: this.id, placeId: option.place_Id }));
	}

	stateProvinceEnabled() {
		const countryCode = this.formGroup.get(this.COUNTRY)?.value?.code;
		return countryCode === CountryCode.US || countryCode === CountryCode.CA;
	}

	setAddressFields(details: GooglePlaceDetails) {
		let postalCode = "";
		let streetAddress = "";

		details.address_Components!.forEach((detail: GooglePlaceDetail) => {
			const detailType = detail.types![0];
			switch (detailType) {
				case GoogleAddress.STREET_NUMBER: {
					// Street Number of Address 1, e.g 1
					streetAddress += detail.long_Name;
					break;
				}
				case GoogleAddress.ROUTE: {
					// Route For Address 1, e.g, Main St.
					streetAddress += ` ${detail.long_Name}`;
					this.formGroup?.get(this.ADDRESS1)?.patchValue(streetAddress);
					break;
				}
				case GoogleAddress.CITY: {
					// City
					this.formGroup?.get(this.CITY)?.patchValue(detail.long_Name);
					break;
				}
				case GoogleAddress.ALTERNATE_CITY: {
					// Value for City for certain cities, e.g. New York City
					this.formGroup?.get(this.CITY)?.patchValue(detail.long_Name);
					break;
				}
				case GoogleAddress.COUNTRY: {
					// Map Googles value for US to Limestone value
					if (detail.long_Name === "United States") {
						detail.long_Name = "United States of America";
					}
					// Adds states to display if country is US, Add provinces to display if country is CA
					if (detail.short_Name === CountryCode.US || detail.short_Name === CountryCode.CA) {
						this.filteredStateProvinces = copy(this.stateProvinces).filter((sp) => sp.country === detail.short_Name);
					}
					this.formGroup?.get(this.COUNTRY)?.patchValue(this.countries.find((c) => c.code === detail.short_Name));
					break;
				}
				case GoogleAddress.STATE_PROVINCE: {
					// State/Province
					this.formGroup?.get(this.STATE)?.patchValue(this.stateProvinces.find((sp) => sp.code === detail.short_Name));
					break;
				}
				case GoogleAddress.POSTAL_CODE: {
					postalCode = `${detail.long_Name}`;
					this.formGroup?.get(this.POSTAL_CODE)?.patchValue(postalCode);
					break;
				}
			}
		});
	}
}
