import { Store } from "@ngrx/store";
import { RouteStepDataBaseComponent } from "src/app/Core/route-step-data-base.component";
import { RouteStepDataService } from "../../../Services";
import { Component, OnInit } from "@angular/core";
import { CodatSelectors, CompanyProfileSelectors } from "src/app/AppStateManagement";
import { combineLatest, filter, map, takeUntil } from "rxjs";
import { DisplayedBankAcccount } from "src/app/Models/DisplayedBankAccount";

@Component({
	selector: "ls-confirm-bank-account",
	templateUrl: "./confirm-bank-account.component.html",
	styleUrls: ["./confirm-bank-account.component.scss"]
})
export class ConfirmBankAccountComponent extends RouteStepDataBaseComponent implements OnInit {
	public bankName?: string;
	public accountNumber?: string;

	constructor(
		public store: Store,
		public routeStepDataService: RouteStepDataService,
		private _codatSelectors: CodatSelectors,
		private _companyProfileSelectors: CompanyProfileSelectors
	) {
		super(routeStepDataService);
	}

	public ngOnInit(): void {
		combineLatest([
			this.store.select(this._codatSelectors.selectBankAccounts),
			this.store.select(this._companyProfileSelectors.selectCompanyProfile)
		])
			.pipe(
				filter(([ba, cp]) => !!ba && !!cp),
				takeUntil(this.componentTeardown$),
				map(([bankAccounts, companyProfile]) => {
					bankAccounts!.map((ba: DisplayedBankAcccount) => {
						if (ba.name === companyProfile!.bankAccount?.alias) {
							this.bankName = ba.name;
							this.accountNumber = ba.accountNumber;
						}
					});
				})
			)
			.subscribe();
	}

	public onClick(index = 0): void {
		this.nav(index);
	}
}
