<section class="d-flex flex-column flex-grow-1">
  <div class="ls-stepper-container" [ngClass]="{'expanded': expanded}">
    <div class="ls-step-container align-items-center" [ngClass]="{'expanded': expanded}">
      <div [class.d-none]="!expanded" [class.d-sm-block]="!expanded">
        <button class="ls-stepper-nav btn btn-lg btn-icon ls-p-0" 
          [class.invisible]="!displayNavBackButton" (click)="navBack()">
          <span class="material-icons md-18">arrow_back </span>
          <label class="xl hoverable ls-ml-1">{{navBackName}}</label>
        </button>
      </div>
      <ng-container *ngFor="let step of steps; let i = index">
        <div class="ls-step d-flex flex-column" [class.active-step]="(i === activeStep && showActiveStep) || expanded">
          <div class="ls-step-items d-flex align-items-center" [class.hoverable]="lsSteps[i].label !== '' && isCompleted(i)" (click)="emitStepClick(i)">
            <div class="ls-icon-stepper d-flex justify-content-center align-items-center ls-mr-2"
                 [class.ls-stepper-icon-complete]="isCompleted(i) && !(i === selectedIndex && showActiveStep)"
                 [class.ls-stepper-icon-active]="i === selectedIndex && showActiveStep"
                 [class.ls-stepper-icon]="i !== selectedIndex && !isCompleted(i)"
                 [class.hoverable]="lsSteps[i].label !== '' && isCompleted(i)">
              <span [ngClass]="{'material-icons': hasIcon(i)}">{{getIcon(i)}}</span>
            </div>
						<label [class.ls-stepper-label-active]="i === selectedIndex && showActiveStep"
									 [class.ls-stepper-label-complete]="isCompleted(i) && !(i === selectedIndex && showActiveStep)"
									 [class.ls-stepper-label]="i !== selectedIndex || !showActiveStep"
                   [class.hoverable]="lsSteps[i].label !== '' && isCompleted(i)"
									 [class.xl]="!displayNavBackButton" [class.l]="displayNavBackButton">{{lsSteps[i].label}}</label>
          </div>
          <div class="ls-progression-line" *ngIf="i !== steps.length-1" [class.expanded]="expanded">
            <div class="ls-progression-percent" [style.height]="getPercent(i)"></div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="ls-stepper-action-container d-flex justify-content-between" [class.expanded]="expanded">
      <div class="ls-stepper-settings-btn d-flex flex-row align-items-center w-75 justify-content-start justify-content-sm-between" 
           [class.expanded]="expanded">
        <div class="hoverable d-flex flex-row justify-content-start align-items-center ls-mr-6" (click)="logoutClicked.emit()">
          <span class="material-icons ls-mr-2" id="logout">logout</span>
          <label class="l hoverable" for="logout">Log Out</label>
        </div>
        <div class="ls-mr-3 ls-mr-md-0">
          <span class="material-icons" *ngIf="displaySettingsButton" (click)="handleSettingsClicked()">settings</span>
        </div>
      </div>
      <div class="mobile-accordion-btn">
          <span class="material-icons"
                (click)="setExpandedState()">{{expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</span>
      </div>
    </div>
  </div>
  <div class="ls-stepper-content" [ngTemplateOutlet]="selected ? selected.content : null">
  </div>
</section>