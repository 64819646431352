import { Injectable } from "@angular/core";
import { FileHandle } from "src/Elements/upload-widget/FileHandle";

@Injectable()
export class FileUtilityService {
	/**
	 * Returns a map of ALL allowed file extensions. The key is the file extension and the value
	 * is a boolean indicating if the file extension should be displayed in the UI.
	 * @returns Map<string, boolean>
	 */
	public static getAllowedFileExtensions(): Map<string, boolean> {
		return new Map<string, boolean>([
			["JPEG", true],
			["PNG", true],
			["GIF", true],
			["PDF", true],
			["JPG", false],
			["JFIF", false],
			["JPE", false],
			["JIF", false]
		]);
	}

	public static convertFileSize(size: number): string {
		const sizes: string[] = ["B", "KB", "MB", "GB", "TB"];
		let order = 0;
		let len = size;
		while (len >= 1024 && order < sizes.length - 1) {
			order++;
			len = len / 1024;
		}
		return `${Math.round(len)} ${sizes[order]}`;
	}

	public static convertFileSizeToBytes(fileSize: string): number {
		const fileSizeNumber = Number(fileSize.split(" ")[0]);
		const fileSizeUnit: string = fileSize.split(" ")[1];

		let byteValue: number;

		switch (fileSizeUnit) {
			case "B":
				byteValue = fileSizeNumber;
				break;
			case "KB":
				byteValue = fileSizeNumber * 1024;
				break;
			case "MB":
				byteValue = fileSizeNumber * 1048576;
				break;
			case "GB":
				byteValue = fileSizeNumber * 1073741824;
				break;
			case "TB":
				byteValue = fileSizeNumber * 1099511627776;
				break;
			default:
				throw new Error('Invalid unit. Use "KB", "MB", "GB" or "TB".');
		}
		return byteValue;
	}

	public static fileTypeSupported(file: FileHandle, allowedFileExtensions: string[]): boolean {
		const fileType = this.getFileType(file);
		const supported = allowedFileExtensions.includes(fileType!.toUpperCase());
		return supported;
	}

	public static getFileType(file: FileHandle): string {
		return file.file.name.split(/[.]+/).pop()!;
	}

	public static isFileSizeOverLimit(file: FileHandle, maxFileSize: number): boolean {
		const maxBytes = this.convertFileSizeToBytes(maxFileSize + " MB");
		const overLimit: boolean = file.file.size > maxBytes ? true : false;
		return overLimit;
	}
}
