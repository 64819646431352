import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { filter, map, Subject, takeUntil } from "rxjs";
import { Store } from "@ngrx/store";
import { MsalService } from "@azure/msal-angular";
import { EndSessionRequest } from "@azure/msal-browser";
import { IRouteStepData } from "src/app/Models/Interfaces";
import { DOCUMENT } from "@angular/common";
import copy from "fast-copy";
import { ActiveState } from "../../../../AppStateManagement/Stepper/stepper-reducer";
import { StepperSelectors } from "src/app/AppStateManagement";
import { RouteStepDataService } from "../../Services";

@Component({
	selector: "ls-onboarding-stepper",
	templateUrl: "./onboarding-stepper.component.html",
	styleUrls: ["./onboarding-stepper.component.scss"]
})
export class OnboardingStepperComponent implements OnInit, OnDestroy {
	protected componentTeardown$ = new Subject();
	public expanded?: boolean;
	public displayState?: ActiveState;
	private parentState?: ActiveState;
	private childState?: ActiveState;
	public hasStepperContextChanged = false;
	public showActiveStep = true;
	public routeStepData?: Map<string, IRouteStepData>;
	public activeRouteStepData?: IRouteStepData;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private store: Store,
		private stepperSelectors: StepperSelectors,
		private authService: MsalService,
		@Inject(DOCUMENT) private document: Document,
		private routeStepDataService: RouteStepDataService
	) {}

	ngOnInit() {
		this.store
			.select(this.stepperSelectors.selectState)
			.pipe(
				filter((stepperState) => !!stepperState && !!stepperState.activeRouteStepData),
				takeUntil(this.componentTeardown$),
				map((stepperState) => {
					this.routeStepData = stepperState.routeStepData;
					this.activeRouteStepData = stepperState.activeRouteStepData;
					if (this.activeRouteStepData?.backgroundStyle && this.activeRouteStepData?.backgroundStyle !== "") {
						this.document.body.classList.add(this.activeRouteStepData?.backgroundStyle);
					} else {
						this.document.body.className = "";
					}

					this.displayState = stepperState.activeState;
					this.showActiveStep =
						this.activeRouteStepData?.step === this.displayState?.activeStep ||
						stepperState.activeState!.childStepsActive;

					if (stepperState.activeState!.childStepsActive) {
						this.parentState = stepperState.activeState!.parent;
						this.childState = stepperState.activeState!;
					}
				})
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.document.body.className = "";
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	handleStepChange(index: number) {
		if (this.hasStepperContextChanged && index === this.displayState?.activeStep) {
			this.displayState = this.childState;
			this.hasStepperContextChanged = false;
		} else {
			if (this.displayState?.activeSteps[index].percentComplete === 100) {
				const step = copy(this.displayState?.activeSteps[index]);
				if (step.route) {
					if (this.displayState.childStepsActive) {
						const relationshipCompanyId = this.router.url.split("/").pop();
						this.routeStepDataService.navByPath(
							step.route,
							new Map<string, string | number>([["relationshipCompanyId", relationshipCompanyId!]])
						);
					} else {
						this.routeStepDataService.navByPath(step.route);
					}
				}
			}
		}
	}

	handleNavClick() {
		this.hasStepperContextChanged = true;
		this.displayState = this.parentState;
	}

	handleSettingsClick() {
		this.router.navigate(["home"]);
	}

	logout() {
		const request: EndSessionRequest = {
			account: this.authService.instance.getActiveAccount(),
			postLogoutRedirectUri: window.origin + "/logout",
			idTokenHint: this.authService.instance.getActiveAccount()?.idToken
		};
		this.authService.logout(request);
	}
}
