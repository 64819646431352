import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Flow, Product } from "src/app/Models/Enums";

@Injectable({
	providedIn: "root"
})
export class FlowService {
	getProductIdFromRoute(snapshot: ActivatedRouteSnapshot[]): Product | undefined {
		const flowId = this.findFlowId(snapshot);
		return this.mapFlowToProduct(flowId);
	}

	private findFlowId(pathFromRoot: ActivatedRouteSnapshot[]): string | undefined {
		for (const snapshot of pathFromRoot) {
			const flowId = snapshot.params["flowId"];
			if (flowId && Object.values(Flow).includes(flowId as Flow)) {
				return flowId;
			}
		}
		return undefined;
	}

	private mapFlowToProduct(flowId: string | undefined): Product | undefined {
		switch (flowId) {
			case Flow.ARF_V1:
			case Flow.ARF_PG:
				return Product.ARF;
			case Flow.SCF_V1:
				return Product.SCF;
			default:
				return undefined;
		}
	}
}
