import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { CodatActions, CodatSelectors, CompanyProfileSelectors } from "../../../../../../AppStateManagement";
import { ConnectFinancialsResultService } from "src/services/connect-financials-result.service";
import { CodatConnectionType } from "src/app/Models/Enums/CodatConnectionType";
import { BehaviorSubject, Subject, combineLatest, filter, map, takeUntil } from "rxjs";
import { CompanyProfile } from "src/app/Models";
import { RouteStepDataBaseComponent } from "src/app/Core/route-step-data-base.component";
import { RouteStepDataService } from "../../../../Services";
import { IHtmlContent } from "src/app/Models/Interfaces";
import { Flow, Institution } from "src/app/Models/Enums";
import { DisplayedBankAcccount } from "src/app/Models/DisplayedBankAccount";
import { FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { LsValidators } from "@limestone/ls-shared-modules";

@Component({
	selector: "ls-bank-account-result",
	templateUrl: "./bank-account-result.component.html",
	styleUrls: ["./bank-account-result.component.scss"],
	providers: [ConnectFinancialsResultService]
})
export class BankAccountResultComponent extends RouteStepDataBaseComponent implements OnInit, OnDestroy {
	public componentTeardown$ = new Subject();
	public success$: BehaviorSubject<boolean> = this._connectFinancialsResultService.success$;
	public companyProfile?: CompanyProfile;
	public path = "";
	public data: BankResultContent;
	public scfFlow?: boolean;
	public bankAccounts?: DisplayedBankAcccount[];
	public selectedBankAccounts?: string[] = [];
	public form: FormGroup = new FormGroup({});
	public ACCOUNT_NUMBER = "accountNumber";
	public isSelectedChaseAccount?: boolean;
	private validator: ValidatorFn;

	public allowedKeys: string[] = ["Backspace", "Delete", "Control", "ArrowRight", "ArrowLeft", "v"];

	constructor(
		public router: Router,
		public store: Store,
		public companyProfileSelectors: CompanyProfileSelectors,
		private _connectFinancialsResultService: ConnectFinancialsResultService,
		public routeStepDataService: RouteStepDataService,
		public codatSelectors: CodatSelectors
	) {
		super(routeStepDataService);
		const htmlContent: any = this.activeRouteData!.htmlContent;

		this.data = new BankResultContent(
			htmlContent.header,
			htmlContent.paragraph,
			htmlContent.firstButtonText,
			htmlContent.failedText
		);
		this.validator = Validators.compose([Validators.required, Validators.maxLength(46)]) || Validators.nullValidator;
	}

	public ngOnInit() {
		combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.companyProfileSelectors.selectOnboardCompanyProgress),
			this.store.select(this.codatSelectors.selectBankAccounts)
		])
			.pipe(
				filter(([cp, ocp]) => !!cp && !!ocp),
				takeUntil(this.componentTeardown$),
				map(([cp, ocp, ba]) => {
					this.companyProfile = cp;
					this.scfFlow = ocp?.onboardingConfiguration?.id === Flow.SCF_V1;
					this._connectFinancialsResultService.init(CodatConnectionType.BANKING);
					this.path = `onboarding/${ocp!.onboardingConfigurationId}/connect-financials/banking-success`;
					this.bankAccounts = ba;
					this.selectedBankAccounts = [];
					if (cp?.bankAccount?.codatBankAccountId && ba?.find((a) => a.id === cp?.bankAccount?.codatBankAccountId)) {
						this.selectedBankAccounts?.push(cp.bankAccount.codatBankAccountId);
					}
					this.form.addControl(this.ACCOUNT_NUMBER, new FormControl("", [this.validator]));
				})
			)
			.subscribe();
	}

	public ngOnDestroy(): void {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	public isDisabled() {
		if (this.scfFlow && this.form.invalid) {
			return true;
		}
		return this.scfFlow && this.selectedBankAccounts?.length === 0;
	}

	public isSelected(id: string) {
		return this.selectedBankAccounts?.includes(id);
	}

	public connectBank() {
		if (this.scfFlow && this.selectedBankAccounts?.length === 1) {
			this.store.dispatch(
				CodatActions.saveBankAccount({
					companyId: this.companyProfile!.companyId!,
					codatConnection: { id: this.selectedBankAccounts![0] }
				})
			);
			this.continue();
		} else {
			this.store.dispatch(
				CodatActions.createConnection({ companyProfile: this.companyProfile!, redirectPath: this.path })
			);
		}
	}

	public selectBankAccount(id: string) {
		this.selectedBankAccounts = [];
		this.selectedBankAccounts?.push(id);
		if (this.selectedBankAccounts?.length > 0) {
			const selectedBankAccount = this.bankAccounts?.find((ba) => ba.id === this.selectedBankAccounts![0]);
			if (selectedBankAccount?.institution === Institution.Chase) {
				this.isSelectedChaseAccount = true;
				const lastFourDigits = selectedBankAccount.accountNumber.slice(-4);
				this.validator =
					Validators.compose([
						Validators.required,
						Validators.maxLength(46),
						Validators.pattern(`.*${lastFourDigits}$`)
					]) || Validators.nullValidator;
				this.form.controls[this.ACCOUNT_NUMBER].setValidators([this.validator]);
				this.form.controls[this.ACCOUNT_NUMBER].enable();
			} else {
				this.isSelectedChaseAccount = false;
				this.form.controls[this.ACCOUNT_NUMBER].disable();
			}
		}
	}

	controlHasError(controlName: string): boolean {
		if (this.form.get(controlName)) {
			return (
				this.form.get(controlName)!.invalid &&
				(this.form.get(controlName)!.dirty || this.form.get(controlName)!.touched)
			);
		}
		return false;
	}

	getControlError(): string {
		return "Last 4 digits entered must match the last 4 digits of selected account number";
	}

	public onKeyboardEvent(event: KeyboardEvent): void {
		if (
			!LsValidators.allowedInput(event, /(\d)+/g, this.allowedKeys) ||
			(this.form.get(this.ACCOUNT_NUMBER)?.value !== null && this.form.get(this.ACCOUNT_NUMBER)?.value?.length >= 46)
		) {
			if (event.key !== "Delete" && event.key !== "Backspace") {
				event.preventDefault();
			}
		}
	}

	public onClipboardEvent(event: ClipboardEvent): void {
		if (!LsValidators.allowedInput(event, /^\d{46}$/, this.allowedKeys)) {
			event.preventDefault();
		}
	}

	public continue() {
		this.nav();
	}
}

class BankResultContent implements IHtmlContent {
	public header: string;
	public paragraph: string;
	public firstButtonText?: string;
	public failedText?: string;

	constructor(header: string, paragraph: string, firstButtonText?: string, failedText?: string) {
		this.header = header;
		this.paragraph = paragraph;
		this.firstButtonText = firstButtonText;
		this.failedText = failedText;
	}
}
