import { NgModule } from "@angular/core";
import { RouterModule, Routes, mapToCanActivate } from "@angular/router";
import { InvitationComponent, LogoutComponent } from "../Core";
import { MsalGuard, MsalRedirectComponent } from "@azure/msal-angular";
import { FeatureFlagGuard, FeatureFlags } from "@limestone/ls-shared-modules";
import { UserIPResolver } from "../Resolvers";

export const baseRoutes: Routes = [
	{
		path: "",
		pathMatch: "full",
		redirectTo: "onboarding"
	},
	{
		path: "onboarding",
		loadChildren: () => import("../Modules/COT-Module/COT.module").then((m) => m.CotModule)
	},
	{
		path: "home",
		canActivate: mapToCanActivate([MsalGuard, FeatureFlagGuard]),
		data: {
			featureFlag: FeatureFlags.Onboarding_V1_0_0
		},
		loadChildren: () => import("../Modules/Root-Module/Root.module").then((m) => m.RootModule)
	},
	{
		path: "authorized",
		canActivate: mapToCanActivate([MsalGuard, FeatureFlagGuard]),
		data: {
			featureFlag: FeatureFlags.Onboarding_V1_0_0
		},
		component: MsalRedirectComponent
	},
	{
		path: "invitation/:flowId",
		component: InvitationComponent
	},
	{
		path: "logout",
		component: LogoutComponent
	}
];

@NgModule({
	imports: [RouterModule.forRoot(baseRoutes, { scrollPositionRestoration: "enabled" })],
	exports: [RouterModule],
	providers: [UserIPResolver]
})
export class AppRoutingModule {}
