<div class="d-flex flex-column flex-grow-1">
    <div class="w-100 d-flex flex-column flex-lg-row justify-content-between ls-mb-8">
        <h1 class="m ls-mb-2 ls-mb-lg-0">Applications</h1>
        <button *ngIf="applications.length > 0" class="btn btn-secondary app-cnt-btn ls-mb-0" (click)="moreFinancing()">Apply for more financing</button>
    </div>

	<div *ngIf="applications.length > 0; else emptyState">
		<ls-alert *ngIf="hasArfAppWithNoCustomers" alertType="secondary" [small]="true" class="ls-mb-4 w-100 hoverable" (click)="continueApplication()">
			<p class="m ls-mb-0">You have an ARF application in progress with no customers. Click here to resume.</p>
		</ls-alert>
		<div class="d-none d-xxl-block">
            <table class="table align-middle ls-mb-3 bg-white">
                <thead>
                <tr>
                    <th *ngFor="let column of columns" class="header-cell">
                        <div class="d-flex align-items-center">
                            <label class="s-caps">{{column.column}}</label>
                            <span type="button" *ngIf="column.canSort" class="material-icons ls-ml-2" (click)="gridService.sortColumn(column)">
                                {{gridService.getIcon(column)}}
                            </span>
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let app of applications" (click)="onRowClicked(app)">
                    <td><p class="m-bold ls-mb-0">{{app.companyName}}</p></td>
                    <td><p class="m ls-mb-0">{{app.companyId}}</p></td>
                    <td><p class="m ls-mb-0">{{app.createDate | date: 'MMM dd, YYYY'}}</p></td>
                    <td><p class="m ls-mb-0">{{getLastActivity(app.lastActivity)}}</p></td>
                    <td>
                        <div
                            [class.ls-badge-app-inprogress]="app.applicationStatusId === OnboardApplicationStatus.INPROGRESS"
                            [class.ls-badge-app-submitted]="app.applicationStatusId === OnboardApplicationStatus.SUBMITTED"
                            [class.ls-badge-app-undreview]="app.applicationStatusId === OnboardApplicationStatus.UNDREVIEW"
                            [class.ls-badge-app-complete]="app.applicationStatusId === OnboardApplicationStatus.COMPLETE"
                            [class.ls-badge-app-approved]="app.applicationStatusId === OnboardApplicationStatus.APPROVED">
                            <label class="s-caps">{{app.applicationStatusName}}</label>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="d-xxl-none">
            <div class="application-card d-row ls-mb-3 ls-p-1_75 rounded-7 w-100" *ngFor="let app of applications"  (click)="onRowClicked(app)">
                <div class="d-flex flex-column justify-content-between flex-lg-row ls-mb-3">
                    <label class="l ls-mb-3">{{app.companyName}}</label>
                    <div
                        [class.ls-badge-app-inprogress]="app.applicationStatusId === OnboardApplicationStatus.INPROGRESS"
                        [class.ls-badge-app-submitted]="app.applicationStatusId === OnboardApplicationStatus.SUBMITTED"
                        [class.ls-badge-app-undreview]="app.applicationStatusId === OnboardApplicationStatus.UNDREVIEW"
                        [class.ls-badge-app-complete]="app.applicationStatusId === OnboardApplicationStatus.COMPLETE"
                        [class.ls-badge-app-approved]="app.applicationStatusId === OnboardApplicationStatus.APPROVED">
                        <label class="s-caps">{{app.applicationStatusName}}</label>
                    </div>
                </div>
                <div class="d-none d-lg-block d-xl-block d-xxl-none">
                    <div class="d-flex justify-content-between">
                        <div class="ls-mb-3 ls-mb-lg-0">
                            <label class="ls-mb-1 s">ID</label>
                            <p class="ls-mb-0 l-bold">{{app.companyId}}</p>
                        </div>
                        <div>
                            <label class="ls-mb-1 s">Created</label>
                            <p class="ls-mb-0 l-bold">{{app.createDate | date: 'MMM dd, YYYY'}}</p>
                        </div>
                        <div>
                            <label class="ls-mb-1 s">Last Activity</label>
                            <p class="ls-mb-0 l-bold">{{getLastActivity(app.lastActivity)}}</p>
                        </div>
                    </div>
                </div>
                <div class="d-lg-none">
                    <div class="d-flex flex-column">
                        <div class="ls-mb-3 ls-mb-lg-0">
                            <label class="ls-mb-1 s">ID</label>
                            <p class="ls-mb-0 l-bold">{{app.companyId}}</p>
                        </div>
                        <div class="d-flex flex-row justify-content-between">
                            <div>
                                <label class="ls-mb-1 s">Created</label>
                                <p class="ls-mb-0 l-bold">{{app.createDate | date: 'MMM dd, YYYY'}}</p>
                            </div>
                            <div>
                                <label class="ls-mb-1 s">Last Activity</label>
                                <p class="ls-mb-0 l-bold">{{getLastActivity(app.lastActivity)}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ls-pagination [page]="gridService.activePage" (update)="gridService.handlePageChange($event)"></ls-pagination>
    </div>
</div>
<ng-template #emptyState>
    <div class="d-flex flex-column flex-grow-1">
        <div class="d-flex flex-column justify-content-center align-items-center">
            <div class="application-logo ls-mb-5">
                <img src="../../../../../assets/empty-applications-placeholder.svg" alt="raistone-white"/>
            </div>
            
            <div class="app-info-container d-flex flex-column justify-content-center align-items-center">
                <p class="title m ls-mb-3">Application in progress</p>
                <p class="l ls-mb-5">Looks like you haven’t submitted your first application.</p>
                <button class="btn btn-primary app-cnt-btn" (click)="continueApplication()">Continue application</button>
            </div>
        </div>
    </div>
</ng-template>
