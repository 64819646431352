<div class="d-flex flex-column">
	<h1 class="flex-column ls-mb-8 l">Review application</h1>
	<div class="ls-mb-11 d-flex flex-column">
		<ng-container *ngFor="let step of applicationSteps; let i = index; let isLast = last;">
			<div class="d-flex justify-content-between flex-column" [class]="isLast ? 'ls-mb-0' : 'ls-mb-4'">
				<div class="d-flex" [class.ls-mb-2]="step.label === ApplicationSection.CUSTOMER_SETUP">
					<div class="ls-icon-medium" [class]="getSectionIconClass(step.label)">
						<span class="material-icons">{{getSectionIcon(step.label)}}</span>
					</div>
					<label class="l hoverable" (click)="navToStep(i + 1)">{{step.label}}</label>	
				</div>
				<div class="d-flex" *ngIf="step.label === ApplicationSection.CONNECT_FINANCIALS">
					<p class="m ls-pl-7 ls-pl-md-0">{{getConnectFinancialsMessage()}}</p>
				</div>
				<div class="d-flex flex-column" *ngIf="step.label === ApplicationSection.CUSTOMER_SETUP">
					<ul class="no-bullets ls-mb-0 ls-pl-11">
						<li *ngFor="let cpr of companyProfileRelationships; last as last;" class="ls-pl-0_75" [class.ls-mb-2]="!last" [class.ls-mb-0]="last">						
							<div class="d-flex justify-content-between flex-column flex-md-row">
								<div class="d-flex">
									<div class="ls-icon-medium" 
											[class.ls-icon-error-no-background]="!cpr.isCustomerSetupComplete"
											[class.ls-icon-primary-no-background]="cpr.isCustomerSetupComplete">
										<span class="material-icons">{{cpr.isCustomerSetupComplete ? 'check_circle' : 'error'}}</span>
									</div>
									<label class="l hoverable" (click)="navToCustomer(cpr)">{{cpr.relationshipCompanyName}}</label>
								</div>
								<div class="d-flex">
									<p class="m ls-pl-7 ls-pl-md-0 " *ngIf="!cpr.isCustomerSetupComplete!">Please complete setting up this customer</p>
								</div>
							</div>
						</li>
					</ul>				
				</div>
			</div>
		</ng-container>
	</div>
	<div class="d-flex flex-row">
		<button type="button" (click)="submitModal()" class="btn btn-lg btn-primary" [disabled]="!canSubmitApplication">Submit application</button>
	</div>
</div>