import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { Application, CompanyProfileRelationship, Page } from "src/app/Models";
import { HttpService } from "src/services/HTTP";
import { PageRequest } from "@limestone/ls-shared-modules";

@Injectable()
export class CompanyProfileRelationshipService extends HttpService {
	constructor(public http: HttpClient) {
		super(http);
		this.path = "/api/companyProfileRelationship";
	}

	getCompanyProfileRelationship(
		companyProfileId: number,
		relationshipCompanyId: number
	): Observable<CompanyProfileRelationship> {
		const url = `${this.path}/${relationshipCompanyId}/companyProfile/${companyProfileId}`;
		return this.http
			.get(url)
			.pipe(
				map(
					(resp: CompanyProfileRelationship) =>
						new CompanyProfileRelationship(
							resp.companyProfileId,
							resp.productId,
							resp.relationshipCompanyId,
							resp.relationshipCompanyName,
							resp.billingAddress,
							resp.primaryContact,
							resp.accountsPayableContact,
							resp.instrumentPlatform,
							resp.instrumentApprovalPlatform,
							resp.remittancePlatform,
							resp.relationshipDurationId,
							resp.paymentTerms,
							resp.currencyCode,
							resp.onboardPaymentMethodId,
							resp.isOtherCurrency,
							resp.preferredPaymentMethodId,
							resp.onboardInstrumentRangeId,
							resp.onboardRevenueRangeId,
							resp.arBalanceRangeId,
							resp.isInstrumentApprovalConfirmed,
							resp.isAutoXcel,
							resp.financeDisclosureId,
							resp.isFinanceExpedited,
							resp.requestedCreditLimit,
							resp.isManualSetup,
							resp.isCustomerSetupComplete,
							resp.applicationStatusId,
							resp.applicationStatusName,
							resp.currentSubStep,
							resp.isCodatCalculationsComplete,
							resp.createDate,
							resp.lienCollaborationYesNoId,
							resp.subsidiaryCompanies
						)
				)
			);
	}

	getCompanyProfileRelationships(
		companyProfileId: number,
		unenrolled = false
	): Observable<CompanyProfileRelationship[]> {
		const url = `${this.path}/companyProfile/${companyProfileId}`;
		let params = new HttpParams();
		params = params.set("unenrolled", `${unenrolled}`);
		return this.http
			.get<CompanyProfileRelationship[]>(url, { params })
			.pipe(
				map((cprs: CompanyProfileRelationship[]) =>
					cprs.map(
						(cpr) =>
							new CompanyProfileRelationship(
								cpr.companyProfileId,
								cpr.productId,
								cpr.relationshipCompanyId,
								cpr.relationshipCompanyName,
								cpr.billingAddress,
								cpr.primaryContact,
								cpr.accountsPayableContact,
								cpr.instrumentPlatform,
								cpr.instrumentApprovalPlatform,
								cpr.remittancePlatform,
								cpr.relationshipDurationId,
								cpr.paymentTerms,
								cpr.currencyCode,
								cpr.onboardPaymentMethodId,
								cpr.isOtherCurrency,
								cpr.preferredPaymentMethodId,
								cpr.onboardInstrumentRangeId,
								cpr.onboardRevenueRangeId,
								cpr.arBalanceRangeId,
								cpr.isInstrumentApprovalConfirmed,
								cpr.isAutoXcel,
								cpr.financeDisclosureId,
								cpr.isFinanceExpedited,
								cpr.requestedCreditLimit,
								cpr.isManualSetup,
								cpr.isCustomerSetupComplete,
								cpr.applicationStatusId,
								cpr.applicationStatusName,
								cpr.currentSubStep,
								cpr.isCodatCalculationsComplete,
								cpr.createDate,
								cpr.lienCollaborationYesNoId,
								cpr.subsidiaryCompanies
							)
					)
				)
			);
	}

	getApplications(companyProfileId: number, pageRequest: PageRequest): Observable<Page<Application>> {
		let params = new HttpParams();
		params = params.set("page", pageRequest.page);
		params = params.set("size", pageRequest.size);
		if (pageRequest.sort) {
			pageRequest.sort.forEach((s) => (params = params.append("sort", s)));
		}
		let url = `${this.path}/companyProfile/${companyProfileId}/applications`;
		if (pageRequest.filter) {
			url += `?${pageRequest.filter}`;
		}
		return this.http.get<Page<Application>>(url, { params }).pipe(
			map((apps: Page<Application>) => {
				const content = apps.content!.map(
					(app) =>
						new Application(
							app.companyId,
							app.companyName,
							app.createDate,
							app.lastActivity,
							app.applicationStatusId,
							app.applicationStatusName,
							app.productId,
							app.currentSubStep
						)
				);
				const page: Page<Application> = {
					page: apps.page,
					size: apps.size,
					totalElements: apps.totalElements,
					content: content
				};
				return page;
			})
		);
	}

	createCompanyProfileRelationship(
		companyProfileRelationship: CompanyProfileRelationship
	): Observable<CompanyProfileRelationship> {
		return this.http
			.post<CompanyProfileRelationship>(
				`${this.path}/companyProfile/${companyProfileRelationship.companyProfileId}`,
				companyProfileRelationship
			)
			.pipe(
				map(
					(cpr) =>
						new CompanyProfileRelationship(
							cpr.companyProfileId,
							cpr.productId,
							cpr.relationshipCompanyId,
							cpr.relationshipCompanyName,
							cpr.billingAddress,
							cpr.primaryContact,
							cpr.accountsPayableContact,
							cpr.instrumentPlatform,
							cpr.instrumentApprovalPlatform,
							cpr.remittancePlatform,
							cpr.relationshipDurationId,
							cpr.paymentTerms,
							cpr.currencyCode,
							cpr.onboardPaymentMethodId,
							cpr.isOtherCurrency,
							cpr.preferredPaymentMethodId,
							cpr.onboardInstrumentRangeId,
							cpr.onboardRevenueRangeId,
							cpr.arBalanceRangeId,
							cpr.isInstrumentApprovalConfirmed,
							cpr.isAutoXcel,
							cpr.financeDisclosureId,
							cpr.isFinanceExpedited,
							cpr.requestedCreditLimit,
							cpr.isManualSetup,
							cpr.isCustomerSetupComplete,
							cpr.applicationStatusId,
							cpr.applicationStatusName,
							cpr.currentSubStep,
							cpr.isCodatCalculationsComplete,
							cpr.createDate,
							cpr.lienCollaborationYesNoId
						)
				)
			);
	}

	updateCompanyProfileRelationship(
		companyProfileRelationship: CompanyProfileRelationship,
		isEnrolledCodatCustomer?: boolean
	): Observable<CompanyProfileRelationship> {
		let params = new HttpParams();
		if (isEnrolledCodatCustomer) {
			params = params.set("isEnrolledCodatCustomer", isEnrolledCodatCustomer);
		}
		return this.http
			.put<CompanyProfileRelationship>(
				`${this.path}/${companyProfileRelationship.relationshipCompanyId}/companyProfile/${companyProfileRelationship.companyProfileId}`,
				companyProfileRelationship,
				{
					params
				}
			)
			.pipe(
				map(
					(cpr) =>
						new CompanyProfileRelationship(
							cpr.companyProfileId,
							cpr.productId,
							cpr.relationshipCompanyId,
							cpr.relationshipCompanyName,
							cpr.billingAddress,
							cpr.primaryContact,
							cpr.accountsPayableContact,
							cpr.instrumentPlatform,
							cpr.instrumentApprovalPlatform,
							cpr.remittancePlatform,
							cpr.relationshipDurationId,
							cpr.paymentTerms,
							cpr.currencyCode,
							cpr.onboardPaymentMethodId,
							cpr.isOtherCurrency,
							cpr.preferredPaymentMethodId,
							cpr.onboardInstrumentRangeId,
							cpr.onboardRevenueRangeId,
							cpr.arBalanceRangeId,
							cpr.isInstrumentApprovalConfirmed,
							cpr.isAutoXcel,
							cpr.financeDisclosureId,
							cpr.isFinanceExpedited,
							cpr.requestedCreditLimit,
							cpr.isManualSetup,
							cpr.isCustomerSetupComplete,
							cpr.applicationStatusId,
							cpr.applicationStatusName,
							cpr.currentSubStep,
							cpr.isCodatCalculationsComplete,
							cpr.createDate,
							cpr.lienCollaborationYesNoId
						)
				)
			);
	}

	updateCustomerProgress(companyProfileRelationship: CompanyProfileRelationship): Observable<any> {
		const url = `${this.path}/${companyProfileRelationship.relationshipCompanyId}/companyProfile/${companyProfileRelationship.companyProfileId}/update-progress`;
		return this.http.put(url, companyProfileRelationship.currentSubStep);
	}

	submitApplication(companyProfileId: number): Observable<any> {
		return this.http.put(`${this.path}/companyProfile/${companyProfileId}/submit`, companyProfileId);
	}
}
