<h1 class="l ls-mb-4">Select your account</h1>
<p class="xxl-bold ls-mb-4">
    You're already receiving payments to {{bankName}} account ending in {{accountNumber}}.
</p>
<p class="l ls-mb-11">
    Please confirm if you want to receive payments into this account again or if you'd like to change your account.
</p>
<div class="d-flex flex-sm-row flex-column justify-content-between">
    <button type="button" (click)="onClick()" class="btn btn-primary btn-lg ls-mb-3 ls-mb-md-0">Confirm</button>
    <button type="button" (click)="onClick(1)" class="btn btn-lg btn-text-borderless">Change Account</button>
</div>