import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import {
	AppSelectors,
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors
} from "src/app/AppStateManagement";
import { Observable, filter, map, take } from "rxjs";
import { IRouteStepData } from "../Models/Interfaces";

@Injectable()
export class CompanyProfileRelationshipsDataResolver {
	constructor(
		private store: Store<any>,
		private appSelectors: AppSelectors,
		private companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors
	) {}

	companyProfileRelationships$ = this.store
		.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationships)
		.pipe(
			filter((cpr) => !!cpr),
			take(1)
		);

	resolve(route: ActivatedRouteSnapshot): Observable<any> {
		this.initPrefetchData(route);
		return this.companyProfileRelationships$;
	}

	initPrefetchData(route: ActivatedRouteSnapshot): void {
		const routeData = route.data as IRouteStepData;
		this.store
			.select(this.appSelectors.selectUserRoles)
			.pipe(
				take(1),
				filter((userRole) => !!userRole && userRole.roles.length > 0),
				map((userRole) => {
					this.store.dispatch(
						CompanyProfileRelationshipActions.getCompanyProfileRelationships({
							companyProfileId: userRole!.roles[0].companyId
						})
					);
				})
			)
			.subscribe();
	}
}
