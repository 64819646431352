import { Component, ViewChild } from "@angular/core";
import { CompanyProfileRelationship } from "src/app/Models";
import { Store } from "@ngrx/store";
import { filter, map, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import copy from "fast-copy";
import { CustomerRelationshipBaseComponent } from "../customer-relationship-base.component";
import { Actions } from "@ngrx/effects";
import { QuestionnaireComponent } from "../../../../../Core";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "src/app/AppStateManagement";
import { RouteStepDataService } from "../../../Services";

@Component({
	selector: "ls-instrument-approval-confirmation",
	templateUrl: "./instrument-approval-confirmation.component.html",
	styleUrls: ["./instrument-approval-confirmation.component.scss"]
})
export class InstrumentApprovalConfirmationComponent extends CustomerRelationshipBaseComponent {
	public companyProfileRelationship?: CompanyProfileRelationship;
	private _isInstrumentApprovalConfirmed = true;
	toggledState = "No";
	get isInstrumentApprovalConfirmed() {
		return this._isInstrumentApprovalConfirmed;
	}
	set isInstrumentApprovalConfirmed(isInstrumentApprovalConfirmed: boolean) {
		this._isInstrumentApprovalConfirmed = isInstrumentApprovalConfirmed;
		this.toggledState = isInstrumentApprovalConfirmed ? "Yes" : "No";
	}
	@ViewChild("questionnaireForm") childForm!: QuestionnaireComponent;
	constructor(
		public actions$: Actions,
		public store: Store,
		public router: Router,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public routeStepDataService: RouteStepDataService
	) {
		super(router, store, actions$, companyProfileSelectors, routeStepDataService, companyProfileRelationshipSelectors);
		this.initData();
	}

	public initData(): void {
		this.store
			.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
			.pipe(
				filter((cpr) => !!cpr),
				takeUntil(this.componentTeardown$),
				map((cpr) => {
					this.companyProfileRelationship = cpr;
					this.isInstrumentApprovalConfirmed = cpr!.isInstrumentApprovalConfirmed!;
				})
			)
			.subscribe();
	}

	continue() {
		if (this.isInstrumentApprovalConfirmed != this.companyProfileRelationship?.isInstrumentApprovalConfirmed) {
			const updatedCompanyProfileRelationship = copy(this.companyProfileRelationship!);
			updatedCompanyProfileRelationship.isInstrumentApprovalConfirmed = this.isInstrumentApprovalConfirmed;
			this.store.dispatch(
				CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
					companyProfileRelationship: updatedCompanyProfileRelationship
				})
			);
		} else {
			this.navigateToNextScreen();
		}
	}
}
