import { Component, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { filter, map, takeUntil } from "rxjs";
import { CompanyProfileRelationship, CompanyProfile } from "src/app/Models";
import copy from "fast-copy";
import { Actions } from "@ngrx/effects";
import { CustomerRelationshipBaseComponent } from "../customer-relationship-base.component";
import { QuestionnaireComponent } from "../../../../../Core";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "src/app/AppStateManagement";
import { GenericLookup, GenericSelectors, LookupTables } from "@limestone/ls-shared-modules";
import { RouteStepDataService } from "../../../Services";

@Component({
	selector: "ls-relationship",
	templateUrl: "./customer-relationship.component.html",
	styleUrls: ["./customer-relationship.component.scss"]
})
export class CustomerRelationshipComponent extends CustomerRelationshipBaseComponent {
	constructor(
		public actions$: Actions,
		public store: Store<any>,
		public router: Router,
		public genericSelectors: GenericSelectors,
		private companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public routeStepDataService: RouteStepDataService
	) {
		super(router, store, actions$, companyProfileSelectors, routeStepDataService);
		this.initData();
	}

	RELATIONSHIP_DURATION = "relationshipDurationId";
	PAYMENT_TERMS = "paymentTerms";
	customerRelationshipDurationForm: FormGroup = new FormGroup({});
	companyProfileRelationship?: CompanyProfileRelationship;
	relationshipDurations: GenericLookup<string>[] = [];
	companyProfile?: CompanyProfile;
	max = 365;
	@ViewChild("questionnaireForm") childForm!: QuestionnaireComponent;
	public initData(): void {
		this.store
			.select(this.genericSelectors.selectLookup(LookupTables.RelationshipDuration))
			.pipe(
				filter((rd) => !!rd && rd.length > 0),
				takeUntil(this.componentTeardown$),
				map((rd) => (this.relationshipDurations = rd!))
			)
			.subscribe();

		this.store
			.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
			.pipe(
				filter((cpr) => !!cpr),
				takeUntil(this.componentTeardown$),
				map((cpr) => {
					this.companyProfileRelationship = cpr;
					if (cpr?.relationshipCompanyName && this.breadcrumbs && this.breadcrumbs.length < 2)
						this.breadcrumbs.push({ routerPath: "", displayText: cpr?.relationshipCompanyName ?? "" });
					this.setFormControls();
				})
			)
			.subscribe();
	}

	setFormControls(): void {
		this.customerRelationshipDurationForm = new FormGroup({
			relationshipDurationId: new FormControl(
				this.companyProfileRelationship?.relationshipDurationId,
				Validators.required
			),
			paymentTerms: new FormControl(
				this.companyProfileRelationship?.relationshipDurationId === undefined &&
				this.companyProfileRelationship?.paymentTerms === 0
					? undefined
					: this.companyProfileRelationship?.paymentTerms,
				[Validators.required, Validators.min(0), Validators.max(this.max), Validators.pattern("^[0-9]+$")]
			)
		});
	}

	continue() {
		if (this.customerRelationshipDurationForm.dirty) {
			const chosenRelationshipDuration = this.customerRelationshipDurationForm.get(this.RELATIONSHIP_DURATION)?.value;
			const chosenPaymentTerms = this.customerRelationshipDurationForm.get(this.PAYMENT_TERMS)?.value;
			const updatedCompanyProfileRelationship = copy(this.companyProfileRelationship!);
			updatedCompanyProfileRelationship.relationshipDurationId = chosenRelationshipDuration;
			updatedCompanyProfileRelationship.paymentTerms = chosenPaymentTerms;
			this.store.dispatch(
				CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
					companyProfileRelationship: updatedCompanyProfileRelationship
				})
			);
		} else {
			this.navigateToNextScreen();
		}
	}

	isInvalid() {
		return this.customerRelationshipDurationForm.invalid;
	}

	isPaymentTermsNotNullOrUndefined() {
		return this.customerRelationshipDurationForm.get(this.PAYMENT_TERMS)?.value != null;
	}

	controlHasError(controlName: string): boolean {
		if (this.customerRelationshipDurationForm.get(controlName)) {
			return (
				this.customerRelationshipDurationForm.get(controlName)!.invalid &&
				(this.customerRelationshipDurationForm.get(controlName)!.dirty ||
					this.customerRelationshipDurationForm.get(controlName)!.touched)
			);
		}
		return false;
	}

	getControlError(controlName: string): string {
		const error: string[] = [];
		if (this.customerRelationshipDurationForm.get(controlName)?.errors!["max"]) {
			error.push("Please limit payment terms to 365 days.");
		}
		if (this.customerRelationshipDurationForm.get(controlName)?.errors!["pattern"]) {
			error.push("Please use whole numbers only.");
		}
		return error.join(" ");
	}
}
