import { CompanyUser } from "./CompanyUser";
import { CompanyAddress } from "./CompanyAddress";
import { CountryCode, GenericLookup } from "@limestone/ls-shared-modules";
import { BankAccount } from "./BankAccount";

export class CompanyProfile {
	companyId?: number;
	industryId?: string;
	legalBusinessName?: string;
	annualRevenueRangeId?: string;
	phoneId?: number;
	countryCode?: string;
	phoneCode?: string;
	phoneNumber?: string;
	phoneExt?: string;
	agreedToTerms?: boolean;
	existingFinances?: GenericLookup<string>[];
	financeFrequency?: string;
	financePurposes?: string[];
	financeRange?: string;
	financeUrgency?: string;
	addresses?: CompanyAddress[];
	lienCollaborationYesNoId?: string;
	integrationDataConnectionId?: string;
	users?: CompanyUser[];
	isAccountingPlatformConnected?: boolean;
	isBankingPlatformConnected?: boolean;
	isCustomersInProgress?: boolean;
	formationStateCode?: string;
	isCodatCustomerFetchComplete?: boolean;
	isInvoiceHistoryFetchComplete?: boolean;
	hasSubmittedApplications?: boolean;
	taxId?: string;
	hasUSBankAccount?: boolean;
	bankAccount?: BankAccount;
	hasScfLead?: boolean;
	hasArfAppWithNoCustomers?: boolean;
	inProgressFlows?: string[];

	constructor(
		companyId?: number,
		industryId?: string,
		legalBusinessName?: string,
		annualRevenueRangeId?: string,
		phoneId?: number,
		countryCode?: string,
		phoneCode?: string,
		phoneNumber?: string,
		phoneExt?: string,
		agreedToTerms?: boolean,
		existingFinances?: GenericLookup<string>[],
		financeFrequency?: string,
		financePurposes?: string[],
		financeRange?: string,
		financeUrgency?: string,
		addresses?: CompanyAddress[],
		lienCollaborationYesNoId?: string,
		integrationDataConnectionId?: string,
		users?: CompanyUser[],
		isAccountingPlatformConnected?: boolean,
		isBankingPlatformConnected?: boolean,
		isCustomersInProgress?: boolean,
		formationStateCode?: string,
		isCodatCustomerFetchComplete?: boolean,
		isInvoiceHistoryFetchComplete?: boolean,
		hasSubmittedApplications?: boolean,
		taxId?: string,
		hasUSBankAccount?: boolean,
		bankAccount?: BankAccount,
		hasScfLead?: boolean,
		hasArfAppWithNoCustomers?: boolean,
		inProgressFlows?: string[]
	) {
		this.companyId = companyId;
		this.industryId = industryId;
		this.legalBusinessName = legalBusinessName;
		this.annualRevenueRangeId = annualRevenueRangeId;
		this.phoneId = phoneId;
		this.phoneCode = phoneCode;
		this.countryCode = countryCode;
		this.phoneNumber = phoneNumber;
		this.phoneExt = phoneExt;
		this.agreedToTerms = agreedToTerms;
		this.existingFinances = existingFinances;
		this.financeFrequency = financeFrequency;
		this.financePurposes = financePurposes;
		this.financeRange = financeRange;
		this.financeUrgency = financeUrgency;
		this.addresses = addresses
			? addresses.map((ca) => new CompanyAddress(ca.companyId, ca.addressType, ca.isActive, ca.address))
			: new Array<CompanyAddress>();
		this.lienCollaborationYesNoId = lienCollaborationYesNoId;
		this.integrationDataConnectionId = integrationDataConnectionId;
		this.users = users
			? users.map(
					(u) =>
						new CompanyUser(
							this.companyId,
							u.userId,
							u.contactId,
							u.isInternal,
							u.firstName,
							u.lastName,
							u.emailAddress,
							u.title,
							u.b2cId,
							u.status,
							u.lastLogin,
							u.userRole,
							u.lastInviteSent
						)
				)
			: new Array<CompanyUser>();
		this.isAccountingPlatformConnected = isAccountingPlatformConnected;
		this.isBankingPlatformConnected = isBankingPlatformConnected;
		this.isCustomersInProgress = isCustomersInProgress;
		this.formationStateCode = formationStateCode;
		this.isCodatCustomerFetchComplete = isCodatCustomerFetchComplete;
		this.isInvoiceHistoryFetchComplete = isInvoiceHistoryFetchComplete;
		this.hasSubmittedApplications = hasSubmittedApplications;
		this.taxId = taxId;
		this.hasUSBankAccount = hasUSBankAccount;
		this.bankAccount = bankAccount
			? new BankAccount(
					bankAccount!.address1,
					bankAccount!.address2,
					bankAccount!.alias!,
					bankAccount!.bankAccountType,
					bankAccount!.city,
					bankAccount!.companyId,
					bankAccount!.country,
					bankAccount!.currency,
					bankAccount!.bankAccountPaymentMethods,
					bankAccount!.postalCode,
					bankAccount!.stateProvince,
					bankAccount!.id,
					bankAccount!.isActive,
					bankAccount!.codatBankAccountId
				)
			: undefined;
		this.hasScfLead = hasScfLead;
		this.hasArfAppWithNoCustomers = hasArfAppWithNoCustomers;
		this.inProgressFlows = inProgressFlows;
	}

	public isAddressOfCountry(countryCode: CountryCode): boolean {
		return !!this.addresses?.some((a) => a.address?.country?.code === countryCode);
	}
}
