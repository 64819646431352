import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { MdbModalService } from "mdb-angular-ui-kit/modal";
import { combineLatest, filter, map, Subject, take, takeUntil } from "rxjs";
import { ContinueApplicationService } from "../../../../../services";
import {
	CompanyProfileSelectors,
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileActions,
	StepperSelectors
} from "../../../../AppStateManagement";
import { CompanyProfile, CompanyProfileRelationship, OnboardCompanyProgress } from "../../../../Models";
import { ApplicationSection, Flow, Product } from "../../../../Models/Enums";
import { SubmitPartialApplicationComponent } from "../../Modals";
import { ModalActions } from "../../../../Core";
import { OnboardApplicationStatus } from "@limestone/ls-shared-modules";
import { RouteStepDataBaseComponent } from "../../../../Core/route-step-data-base.component";
import { RouteStepDataService } from "../../Services";
import { LsStep } from "src/Elements/stepper/limestone-element-stepper.component";
import { FlowService } from "src/services/flow.service";

@Component({
	selector: "ls-review-application",
	templateUrl: "./review-application.component.html",
	styleUrls: ["./review-application.component.scss"]
})
export class ReviewApplicationComponent extends RouteStepDataBaseComponent implements OnInit, OnDestroy {
	private readonly ICON_COMPLETE: string = "check_circle";
	private readonly ICON_PARTIAL: string = "warning";
	private readonly ICON_INCOMPLETE: string = "error";

	private readonly ICON_CLASS_COMPLETE: string = "ls-icon-primary-no-background";
	private readonly ICON_CLASS_PARTIAL: string = "ls-icon-tertiary-no-background";
	private readonly ICON_CLASS_INCOMPLETE: string = "ls-icon-error-no-background";

	protected componentTeardown$ = new Subject();
	applicationSteps!: any;
	companyProfile?: CompanyProfile;
	onboardCompanyProgress?: OnboardCompanyProgress;
	companyProfileRelationships?: CompanyProfileRelationship[];
	completedCustomers?: CompanyProfileRelationship[];
	incompleteCustomers?: CompanyProfileRelationship[];

	isConnectFinancialsComplete = false;
	isCustomerSetupComplete = false;
	canSubmitApplication = false;

	constructor(
		public store: Store,
		public router: Router,
		public route: ActivatedRoute,
		public companyProfileSelectors: CompanyProfileSelectors,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		private continueApplicationService: ContinueApplicationService,
		private dialog: MdbModalService,
		public routeStepDataService: RouteStepDataService,
		public stepperSelectors: StepperSelectors,
		private flowService: FlowService
	) {
		super(routeStepDataService);
	}

	public get ApplicationSection(): typeof ApplicationSection {
		return ApplicationSection;
	}

	ngOnInit() {
		combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.companyProfileSelectors.selectOnboardCompanyProgress),
			this.store.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationships),
			this.store.select(this.stepperSelectors.selectState)
		])
			.pipe(
				filter(([cp, ocp, cprs, ss]) => !!cp && !!ocp && !!cprs && !!ss),
				takeUntil(this.componentTeardown$),
				map(([cp, ocp, cprs, ss]) => {
					this.companyProfile = cp;
					this.onboardCompanyProgress = ocp;
					this.applicationSteps = ss?.steps.filter((step) => step.label !== "Review Application");
					// only customers that have not been submitted yet
					this.companyProfileRelationships = cprs!.filter(
						(cpr) => !cpr.applicationStatusId || cpr.applicationStatusId === OnboardApplicationStatus.INPROGRESS
					);
					this.completedCustomers = [];
					this.incompleteCustomers = [];

					// Connect Financials
					this.isConnectFinancialsComplete =
						(this.onboardCompanyProgress?.productId === Product.SCF ||
							this.companyProfile!.isAccountingPlatformConnected!) &&
						this.companyProfile!.isBankingPlatformConnected!;

					// Customer Setup
					this.companyProfileRelationships.forEach((cpr) => {
						if (cpr.isCustomerSetupComplete) {
							this.completedCustomers!.push(cpr);
						} else {
							this.incompleteCustomers!.push(cpr);
						}
					});
					this.canSubmitApplication =
						this.companyProfileRelationships.some((cpr) => cpr.isCustomerSetupComplete) ||
						(this.onboardCompanyProgress?.productId === Product.SCF &&
							this.onboardCompanyProgress.currentStep === this.activeRouteData?.step);
				})
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	navToStep(step: number) {
		this.nav(step);
	}

	navToCustomer(customer: CompanyProfileRelationship) {
		if (customer.isCustomerSetupComplete) {
			this.nav(4, new Map<string, string | number>([["relationshipCompanyId", customer.relationshipCompanyId!]]));
		} else {
			this.continueApplicationService.continueCustomerSetup(customer.relationshipCompanyId!, customer.currentSubStep!);
		}
	}

	getSectionIcon(appSection: ApplicationSection): string {
		switch (appSection) {
			case ApplicationSection.COMPANY_DETAILS:
				return this.ICON_COMPLETE;
			case ApplicationSection.CONNECT_FINANCIALS:
				return this.getConnectFinancialsIcon();
			case ApplicationSection.CUSTOMER_SETUP:
				return this.getCustomerSetupIcon();
			case ApplicationSection.PROGRAM_SETUP:
				return this.ICON_COMPLETE;
		}
		return "";
	}

	getSectionIconClass(appSection: ApplicationSection): string {
		switch (appSection) {
			case ApplicationSection.COMPANY_DETAILS:
				return this.ICON_CLASS_COMPLETE;
			case ApplicationSection.CONNECT_FINANCIALS:
				return this.getConnectFinancialsIconClass();
			case ApplicationSection.CUSTOMER_SETUP:
				return this.getCustomerSetupIconClass();
			case ApplicationSection.PROGRAM_SETUP:
				return this.ICON_CLASS_COMPLETE;
		}
		return "";
	}

	getConnectFinancialsIcon(): string {
		let icon: string = this.ICON_COMPLETE;
		if (!this.isConnectFinancialsComplete) {
			icon = this.ICON_PARTIAL;
		}

		return icon;
	}

	getConnectFinancialsIconClass(): string {
		let iconClass: string = this.ICON_CLASS_COMPLETE;
		if (!this.isConnectFinancialsComplete) {
			iconClass = this.ICON_CLASS_PARTIAL;
		}

		return iconClass;
	}

	getConnectFinancialsMessage(): string {
		let message = "";
		if (this.onboardCompanyProgress?.productId === Product.ARF && this.companyProfile?.isAccountingPlatformConnected) {
			message = "Please connect your accounting platform";
		} else if (!this.companyProfile?.isBankingPlatformConnected) {
			message = "Please connect your bank account";
		}

		return message;
	}

	getCustomerSetupIcon(): string {
		if (
			this.completedCustomers!.length > 0 &&
			this.completedCustomers!.length === this.companyProfileRelationships!.length
		) {
			return this.ICON_COMPLETE;
		} else if (this.completedCustomers!.length > 0 && this.incompleteCustomers!.length > 0) {
			return this.ICON_PARTIAL;
		} else {
			return this.ICON_INCOMPLETE;
		}
	}

	getCustomerSetupIconClass(): string {
		if (
			this.completedCustomers!.length > 0 &&
			this.completedCustomers!.length === this.companyProfileRelationships!.length
		) {
			return this.ICON_CLASS_COMPLETE;
		} else if (this.completedCustomers!.length > 0 && this.incompleteCustomers!.length > 0) {
			return this.ICON_CLASS_PARTIAL;
		} else {
			return this.ICON_CLASS_INCOMPLETE;
		}
	}

	submitModal() {
		if (this.incompleteCustomers!.length > 0) {
			this.dialog
				.open(SubmitPartialApplicationComponent, {
					modalClass: "modal-dialog-centered modal-fullscreen-sm-down modal-lg",
					ignoreBackdropClick: true,
					data: {
						completedCustomers: this.completedCustomers,
						incompleteCustomers: this.incompleteCustomers
					}
				})
				.onClose.pipe(
					take(1),
					filter((action: ModalActions) => action === ModalActions.PRIMARY),
					map(() => this.submitApplication())
				)
				.subscribe();
		} else {
			this.submitApplication();
		}
	}

	submitApplication() {
		const product = this.flowService.getProductIdFromRoute(this.route.snapshot.pathFromRoot);

		if (product === Product.SCF) {
			this.store.dispatch(
				CompanyProfileRelationshipActions.submitApplication({
					companyProfileRelationship: this.companyProfileRelationships![0]
				})
			);
		} else {
			this.store.dispatch(
				CompanyProfileRelationshipActions.submitApplications({
					companyProfileId: this.companyProfile!.companyId!,
					product: product
				})
			);
		}

		this.onboardCompanyProgress!.isOnboardingComplete = true;
		this.store.dispatch(CompanyProfileActions.updateOnboardCompanyProgress({ progress: this.onboardCompanyProgress! }));
		this.nav();
	}
}
