import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { filter, map, mergeMap, Observable, of, take } from "rxjs";
import {
	AppSelectors,
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	StepperActions
} from "../../../AppStateManagement";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { ChildStepType } from "../../../AppStateManagement/Stepper/stepper-reducer";
import { CompanyProfileRelationship } from "../../../Models";

@Injectable({ providedIn: "root" })
export class CompanyProfileRelationshipResolverService {
	constructor(
		private store: Store<any>,
		private appSelectors: AppSelectors,
		private companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors
	) {}

	companyProfileRelationshipMethod(relationshipCompanyId: string): Observable<any> {
		const customerId = relationshipCompanyId === "new" ? undefined : parseInt(relationshipCompanyId);
		return this.store.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship).pipe(
			filter((cpr) => !!cpr && cpr.relationshipCompanyId === customerId),
			take(1)
		);
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		const id = route.paramMap.get("relationshipCompanyId")!;
		this.initPrefetchData(id);
		return this.companyProfileRelationshipMethod(id);
	}

	initPrefetchData(id: string): void {
		this.store
			.select(this.appSelectors.selectUserRoles)

			.pipe(
				filter((userRole) => !!userRole && userRole.roles.length > 0),
				take(1),
				mergeMap((userRole) => {
					const companyProfileId = userRole!.roles[0].companyId;
					let cprObsv;

					if (id !== "new") {
						const relationshipCompanyId = parseInt(id, 10);
						cprObsv = this.existingCompanyProfileRelationship(companyProfileId, relationshipCompanyId);
					} else {
						cprObsv = this.newCompanyProfileRelationship(companyProfileId);
					}
					return cprObsv;
				})
			)
			.subscribe();
	}

	existingCompanyProfileRelationship(companyProfileId: number, relationshipCompanyId: number): Observable<any> {
		return this.store.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship).pipe(
			take(1),
			filter((cpr) => !cpr || cpr.relationshipCompanyId !== relationshipCompanyId),
			map(() =>
				this.store.dispatch(
					CompanyProfileRelationshipActions.getCompanyProfileRelationship({
						companyProfileId: companyProfileId,
						relationshipCompanyId: relationshipCompanyId
					})
				)
			)
		);
	}

	newCompanyProfileRelationship(companyProfileId: number): Observable<any> {
		return of(
			this.store.dispatch(
				CompanyProfileRelationshipActions.setCompanyProfileRelationship({
					companyProfileRelationship: new CompanyProfileRelationship(companyProfileId)
				})
			)
		);
	}
}
