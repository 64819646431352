import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import copy from "fast-copy";
import { combineLatest, filter, map, Observable } from "rxjs";
import { CompanyProfileSelectors } from "../../../../../AppStateManagement";
import { CompanyDetailsBaseComponent } from "../../company-details-base.component";
import { CompanyProfile } from "../../../../../Models";
import { RouteStepDataService } from "../../../Services";

@Component({
	selector: "ls-company-name",
	templateUrl: "./company-name.component.html",
	styleUrls: ["./company-name.component.scss"]
})
export class CompanyNameComponent extends CompanyDetailsBaseComponent {
	constructor(
		public store: Store,
		public router: Router,
		private companyProfileSelectors: CompanyProfileSelectors,
		public routeStepDataService: RouteStepDataService
	) {
		super(store, router, routeStepDataService);
	}

	LEGAL_NAME = "legalName";

	radioClicked = false;
	companyNameIsLegalName = false;
	confirmedLegalName = false;
	init(): Observable<CompanyProfile | undefined> {
		return combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.companyProfileSelectors.selectOnboardCompanyProgress)
		]).pipe(
			filter(([cp, prog]) => !!cp && !!prog),
			map(([cp, prog]) => {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				if (prog!.currentStep! > 1 || (prog!.currentStep! === 1 && prog!.currentSubStep! > 2)) {
					this.legalName(true);
					this.confirmedLegalName = true;
				}
				this.companyProfile = cp;
				this.form = new FormGroup({
					legalName: new FormControl(this.companyProfile?.legalBusinessName, Validators.required)
				});
				return cp;
			})
		);
	}

	legalName(isLegalName: boolean) {
		this.radioClicked = true;
		this.companyNameIsLegalName = isLegalName;
		if (isLegalName) {
			this.form.get(this.LEGAL_NAME)?.setValue(this.companyProfile?.legalBusinessName);
		} else {
			this.form.get(this.LEGAL_NAME)?.setValue(null);
		}
	}

	onClick() {
		const updatedCompanyProfile = copy(this.companyProfile!);
		if (!this.companyNameIsLegalName) {
			updatedCompanyProfile.legalBusinessName = this.form.get(this.LEGAL_NAME)?.value;
		}
		this.emitSave(updatedCompanyProfile);
		this.nav();
	}

	isInvalid() {
		return !this.radioClicked || this.form.invalid;
	}
}
