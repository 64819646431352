<div class="d-flex flex-column">
    <h1 class="l ls-mb-8">Share your bank account info</h1>
    <p class="l ls-mb-11">This will help us confirm the info for the bank account you want to receive payments.</p>
    <p class="title m ls-mb-3">Your bank details</p>
    <form class="d-flex flex-column ls-w-md-50" [formGroup]="form">
        <mdb-form-control class="ls-mb-4">
            <input mdbInput [formControlName]="BANK_NAME" mdbValidate type="text" id="bankName"
                class="form-control form-control-lg" />
            <label mdbLabel class="form-label" for="bankName">Bank Name</label>
        </mdb-form-control>
        <ls-address-form-control class="ls-mb-11" [formControlName]="BANK_ADDRESS"></ls-address-form-control>
        <div class="ls-mb-11" [class.d-none]="!form.get(BANK_ADDRESS)?.value?.country">
            <ng-container *ngTemplateOutlet="(form.get(BANK_ADDRESS)?.value?.country ? 
            (isUsBased ? usBank : nonUsBank) : null); context: {form: form, label: BANK_ACCOUNT_LABEL}"></ng-container>
        </div>
    </form>
    <button type="button" (click)="onClick()" class="btn btn-icon btn-primary btn-lg" [disabled]="form.invalid"  #lsNextBtn>
        Next <span class="material-icons md-18"> arrow_forward</span>
    </button>
</div>

<ng-template #usBank let-form="form" let-label="label">
    <div [formGroup]="form">
        <p class="title m ls-mb-3">{{label}}</p>
        <mdb-form-control class="ls-mb-4">
            <input mdbInput [formControlName]="ABA_ROUTING" mdbValidate maxlength="24" type="text" id="abaRouting"
                class="form-control form-control-lg" (keydown)="onKeyboardEvent($event)" (paste)="onClipboardEvent($event)"/>
            <label mdbLabel class="form-label" for="abaRouting">ABA / Routing Number</label>
        </mdb-form-control>
        <mdb-form-control>
            <input mdbInput [formControlName]="ACCOUNT_NUMBER" mdbValidate maxlength="46" type="text" id="accountNumber"
                class="form-control form-control-lg" (keydown)="onKeyboardEvent($event)" (paste)="onClipboardEvent($event)"/>
            <label mdbLabel class="form-label" for="accountNumber">Account Number</label>
        </mdb-form-control>
    </div>
</ng-template>

<ng-template #nonUsBank let-form="form" let-label="label">
    <div [formGroup]="form">
        <mdb-form-control class="ls-mb-11">
            <input mdbInput [formControlName]="INTERMEDIARY_CORRESPONDENT_BANK_NAME" mdbValidate maxlength="80" type="text" id="icBankName"
                class="form-control form-control-lg" />
            <label mdbLabel class="form-label" for="icBankName">Intermediary / Correspondent Bank Name</label>
        </mdb-form-control>

        <p class="title m ls-mb-3">{{label}}</p>
        <mdb-form-control class="ls-mb-4">
            <input mdbInput [formControlName]="SWIFT_CODE" mdbValidate type="text" maxlength="24" id="swiftCode"
                class="form-control form-control-lg" (keydown)="onKeyboardEvent($event)" (paste)="onClipboardEvent($event)"/>
            <label mdbLabel class="form-label" for="swiftCode">SWIFT Code</label>
        </mdb-form-control>

        <mdb-form-control>
            <input mdbInput [formControlName]="IBAN_ACCOUNT_NUMBER" mdbValidate maxlength="64" type="text" id="iban"
                class="form-control form-control-lg" (keydown)="onKeyboardEvent($event)" (paste)="onClipboardEvent($event)" />
            <label mdbLabel class="form-label" for="iban">IBAN / Account Number</label>
        </mdb-form-control>
    </div>
</ng-template>